import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Box from '@mui/material/Box';

const ContactSection = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      style={{
        background: `linear-gradient(to bottom , #B52121 33%, #E01B1B 66%)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        color: 'white',
        height: '50px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a
          href='https://www.facebook.com/afinazonemx/'
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon style={{ color: 'white' }} />
        </a>
        <Box
          component="div"
          sx={{ display: { xs: 'none', md: 'block' } }}
          style={{
            fontWeight: 'bold',
            color: 'white',
            textDecoration: 'none',
            cursor: 'pointer',
            marginRight: '10px',
          }}
          onClick={() => openInNewTab('https://www.facebook.com/afinazonemx/')}
        >
          Afinazone MX.
        </Box>

        <a
          href='https://instagram.com/afinazonemx?utm_medium=copy_link'
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon style={{ color: 'white' }} />
        </a>
        <Box
          component="div"
          sx={{ display: { xs: 'none', md: 'block' } }}
          style={{
            fontWeight: 'bold',
            color: 'white',
            textDecoration: 'none',
            cursor: 'pointer',
            marginRight: '10px',
          }}
          onClick={() =>
            openInNewTab('https://instagram.com/afinazonemx?utm_medium=copy_link')
          }
        >
          afinazonemx
        </Box>

        <a
          href='https://www.tiktok.com/@afinazonemx'
          target="_blank"
          rel="noreferrer"
        >
          <MusicNoteIcon style={{ color: 'white' }} />
        </a>
        <Box
          component="div"
          sx={{ display: { xs: 'none', md: 'block' } }}
          style={{
            fontWeight: 'bold',
            color: 'white',
            textDecoration: 'none',
            cursor: 'pointer',
            marginRight: '10px',
          }}
          onClick={() => openInNewTab('https://www.tiktok.com/@afinazonemx')}
        >
          afinazonemx
        </Box>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <a
          href="https://wa.me/524613126256"
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#25d366',
            color: '#FFF',
            borderRadius: '50px',
            fontSize: '28px',
            boxShadow: '2px 2px 3px #999',
            marginRight: '10px',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon />
        </a>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
          461-312-6256
        </Box>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <PhoneIcon style={{ marginRight: '5px' }} />
        <span> 461-312-6256 </span>
      </div>
    </div>
  );
};

export default ContactSection;
