
import React from 'react';
import { COLORS } from 'utils/Constants/Colors';

/* Sections */
import LabelSelector from './Sections/LabelSelector';
import OptionSelector from './Sections/OptionSelector';

/* Helpers */
import { PRODUCT, useQuery } from 'helpers/helpers';

import Box from '@mui/material/Box';

const TYPE_REQUIRE_MODEL_AND_BRAND = {
  'KAT': true,
  'Accs': false,
  'Bj': true,
  'KAM': true,
  'CaAc': true,
  'Ac': true,
  'Flt': true,
  'Enf': true,
  'Cart': true,
};
export default function KitDeAfinacion() {

  let query = useQuery();

  React.useEffect(function () {

    const section = query.get('section');
    setSection(section);

    if (section !== 'Ac') {

      if (!TYPE_REQUIRE_MODEL_AND_BRAND[section]) {
        setOptionSection('product');
        return;
      }

      var car = localStorage.getItem('car');
      if (car === null || car === undefined) {
        setOptionSection('car');
        return;
      }
      var model = localStorage.getItem('model');
      if (model === null || model === undefined) {
        setOptionSection('model');
        return;
      }
    }
    else if (section === 'Ac') {

      var typeOfOil = localStorage.getItem('typeOfOil');
      if (typeOfOil === null || typeOfOil === undefined) {
        setOptionSection('typeOfOil');
        return;
      }
      var brandOil = localStorage.getItem('brandOil');
      if (brandOil === null || brandOil === undefined) {
        setOptionSection('brandOil');
        return;
      }
    }

    setOptionSection('product');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [optionSection, setOptionSection] = React.useState(null);
  const [section, setSection] = React.useState(null);

  return (
    <div>
      <Box
        component='div'
        sx={{
          fontSize: { xs: '30px', md: '70px' },
          height: { xs: '15vh', md: '40vh' }
        }}
        style={{
          background: `linear-gradient(to bottom, ${COLORS.primary} 40%, ${COLORS.secondary} 80%)`,
          color: 'white',
          fontColor: 'black',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Montserrat',
          opacity: '0.7',
        }}
      >
        <div style={{
          width: '90%',
        }}>
          {section && PRODUCT[section]}
        </div>
      </Box>
      <LabelSelector
        optionSection={optionSection}
        setOptionSection={setOptionSection}
        hideArrow={!TYPE_REQUIRE_MODEL_AND_BRAND[section]}
      />
      <OptionSelector
        optionSection={optionSection}
        setOptionSection={setOptionSection}
      />
    </div>
  );
}