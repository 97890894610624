import { doc, collection, setDoc, getDoc, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "ConfigFirebase";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";


export async function addNewProduct(uuid, body, product) {

  let status = true;
  try {
    const KitRef = doc(db, product, uuid);
    await setDoc(KitRef, body, { merge: true });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function getProductAPI(uuid, product) {

  const docRef = doc(db, product, uuid);
  const docSnap = await getDoc(docRef);

  let data = null;

  if (docSnap.exists()) {
    data = docSnap.data();
  } else {
    console.log("No such document!");
  }
  return data;
}

export async function getAllProductsAPI(product) {
  
  const querySnapshot = await getDocs(collection(db, product));
  return querySnapshot;
  
}

export async function deleteProductAPI(uuid, product) {
  let status = true;
  const storage = getStorage();

  try {
    await deleteDoc(doc(db, product, uuid));

    // Create a reference to the file to delete
    const listRef = ref(storage, `images/${product}/${uuid}`);

    listAll(listRef).then((res) => {
      res.items.forEach(async function (itemRef) {
        await deleteObject(itemRef).then(() => {
        }).catch((error) => {
          status = false;
        });
      }
      )
    });

  }
  catch (e) {
    status = false;
  }
  return status;
}
