import React, { useState } from 'react';

import Tune from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Collapse from '@material-ui/core/Collapse';

import Checkbox from '@material-ui/core/Checkbox';

import { PRODUCT } from 'helpers/helpers';

function FiltradoBusqueda({ searchValue, setSearchValue, arraySearch, setArraySearch }) {

    const [open, setOpen] = useState(false);

    return (
        <div style={{
            padding: '5px',
            marginLeft: '150px',
            marginRight: '100px',
            marginTop: '50px',
            borderBottom: '1px solid #000000',
            display: 'flex',
            flexDirection: 'row',
        }}>
            <div style={{
                width: '5%',
                cursor: 'pointer'
            }}>
                <Tune fontSize='large' onClick={() => setOpen(!open)}></Tune>
            </div>
            <div style={{
                width: '20%',
                fontSize: 'x-large',
                borderRight: '1px solid #797979'
            }}>
                Filtrar y ordenar
                <Collapse in={open} timeout="auto">
                    <FilterMenu searched={arraySearch} setSearched={setArraySearch} />
                </Collapse>
            </div>

            <div style={{
                width: '30%',
                height: '30px',
                marginLeft: '20px',
                padding: "3px",
                borderStyle: "solid",
                borderRadius: "10px",
                borderWidth: "1px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "white",
            }}>
                <InputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    style={{
                        width: '90%',
                    }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <SearchIcon />
            </div>
        </div>
    );
}

export default FiltradoBusqueda;

function FilterMenu({ searched, setSearched }) {

    const styleRow = { display: 'flex', fontSize: '15px', alignItems: 'center' };

    function checkProduct(product, state) {
        if (state) {
            const newSearched = [...searched, product];
            setSearched(newSearched);
        }
        else {
            const newSearched = [...searched];
            const index = newSearched.indexOf(product);
            if (index > -1) { 
                newSearched.splice(index, 1);
            }
            setSearched(newSearched);
        }
    };

    return (
        <div>
            {Object.keys(PRODUCT).map((item, index) => {
                return (
                    <div style={styleRow} key={index}>
                        <Checkbox color='primary' onChange={(e) => checkProduct(item, e.target.checked)} />
                        {PRODUCT[item]}
                    </div>
                );
            })}
        </div >
    );
}
