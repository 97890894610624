import { useState, useEffect } from 'react';

/* Material UI */
import { Divider } from '@material-ui/core';

/* Icons */
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/* Helpers */
import { useQuery } from 'helpers/helpers';

import Box from '@mui/material/Box';

export default function LabelSelector(props) {

  let query = useQuery();

  const [type, setType] = useState(null);

  useEffect(() => {
    const productType = query.get('section');
    setType(productType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component='div'
      sx={{
        fontSize: { xs: '20px', md: '50px' },
      }}
      style={{
        color: '#7F7F7F',
        fontWeight: '700',
        paddingBottom: '30px',
        paddingTop: '30px',
        paddingLeft: '8px',
        paddingRight: '8px',
      }}
    >
      { /* Section to select a car */}
      {props.optionSection === 'car' &&
        'Por favor selecciona la marca de tu auto'
      }
      {props.optionSection === 'typeOfOil' &&
        'Por favor selecciona el tipo de aceite'
      }
      { /* Section to select a model */}
      {props.optionSection === 'model' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>

          <span>
            {!props.hideArrow &&
              <ArrowBackIcon
                style={{
                  color: 'black',
                  fontSize: 'inherit',
                  marginRight: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => { props.setOptionSection('car'); localStorage.removeItem('car'); }}
              />
            }
            Por favor selecciona el modelo de tu auto </span>
        </div>
      }
      {props.optionSection === 'brandOil' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
          <span>
            {!props.hideArrow &&
              <ArrowBackIcon
                style={{
                  color: 'black',
                  fontSize: 'inherit',
                  marginRight: '15px',
                  cursor: 'pointer'
                }}
                onClick={() => { props.setOptionSection('typeOfOil'); localStorage.removeItem('brandOil'); }}
              />
            }
            Por favor selecciona la marca de aceite </span>
        </div>
      }
      { /* Section of products */}
      {props.optionSection === 'product' &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
          <span>
            {!props.hideArrow && <ArrowBackIcon
              style={{
                color: 'black',
                fontSize: 'inherit',
                marginRight: '15px',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (type !== 'Ac') {
                  props.setOptionSection('model');
                  localStorage.removeItem('model');
                }
                else {
                  props.setOptionSection('brandOil');
                  localStorage.removeItem('brandOil');
                }
              }}
            />
            }
            ¡Tenemos los siguientes productos para ti! </span>
        </div>
      }
      <Divider
        style={{
          marginTop: '30px',
          backgroundColor: 'black',
          height: '1px',
          marginLeft: '15%',
          marginRight: '15%',
        }}
      />
    </Box>
  );
}