/* Material UI */
import {
  Button,
  Box,
} from "@material-ui/core";

import CurrencyFormat from 'react-currency-format';

/* Icons */
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const container = {
  padding: '15px',
};

const card = {
  padding: '15px',
  backgroundColor: '#d7d7d7',
  border: '1px solid #797979',
  borderRadius: '15px',
  alignItems: 'center',
  fontSize: '20px',
  fontWeight: '300',
};

const row = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '5px',
}

const rowMini = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '5px',
  width: '100%',
}

const strongFont = {
  fontWeight: '500',
};

const buttonStyle = {
  width: '100%',
  marginTop: '30px',
  height: '50px',
};

export default function ShoppingCart({ allAmounts, price, updatePay }) {
  return (
    <div style={container}>
      <div style={card}>

        <Box style={row} sx={{fontSize: { xs: '15px', md:'20px' }}}>
          Precio del carrito
          <div style={strongFont}> $ <CurrencyFormat value={price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
        </Box>

        <Box style={rowMini} sx={{fontSize: { xs: '12px', md:'15px' }}}>
          Incluye IVA
        </Box>

        <hr />
        <LocalShippingIcon style={{ fontSize: '70px' }} />

        <div style={row}>
          Costo de envío
          <div style={strongFont}>
            {price === 0 && 0}
            {price > 0 && (price >= 549 && price > 0 ? '¡Gratis!' : '$ 149.00')}
          </div>
        </div>

        <Box style={rowMini} sx={{fontSize: { xs: '12px', md:'15px' }}}>
          Envío estándar
        </Box>
        <Box style={rowMini} sx={{fontSize: { xs: '12px', md:'15px' }}}>
          Tiempo de entrega estimado es de 2 a 5 días hábiles según el domicilio de entrega
        </Box>

        <hr />

        <Box style={row} sx={{fontSize: { xs: '13px', md:'15px' }}}>
          <div style={strongFont}> Subtotal </div>
          <div style={strongFont}> $ <CurrencyFormat value={parseFloat(price) + parseFloat(price >= 549 || parseInt(price) === 0 ? 0 : 149)} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
        </Box>

      </div>

      <Button
        style={buttonStyle}
        color='primary'
        variant='contained'
        onClick={() => { if(!allAmounts) alert('Selecciona alguna cantidad de productos'); else updatePay(1); }}
      >
        Continuar con la compra
      </Button>
    </div>
  );
}