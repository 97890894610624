import { doc, setDoc, collection, getDocs, deleteDoc, updateDoc, arrayUnion, arrayRemove  } from "firebase/firestore";
import { db } from "ConfigFirebase";

export async function addNewBrandAPI(brand) {

  let status = true;
  try {
    const brandRef = doc(db, 'brands', brand.name);
    await setDoc(brandRef, brand, { merge: true });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function deleteBrandAPI(brand) {

  let status = true;
  try {
    const brandRef = doc(db, 'brands', brand.name);
    await deleteDoc(brandRef);
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function getBrandsAPI() {

  const querySnapshot = await getDocs(collection(db, "brands"));
  return querySnapshot;
}

export async function editBrandModel(brandName, modelName, newName) {
  let status = true;
  try {
    const brandRef = doc(db, 'brands', brandName);

    // Crea nuevo modelo
    await updateDoc(brandRef, {
      models: arrayUnion(newName)
    });
    // Borra modelo anterior
    await updateDoc(brandRef, {
      models: arrayRemove(modelName)
    });

    //Actualiza productos con el modelo anterior
    const categories = ['Accs', 'Bj', 'CaAc', 'Flt', 'KAM', 'KAT'];
    for (const idx in categories){
      let cat = categories[idx]
      const arrayProducts = await getDocs(collection(db, cat));
      arrayProducts.forEach(async (Doc) => {
        let item = Doc.data();
        if (item.model === modelName){
          console.log(doc.id);
          let productRef = doc(db, cat, Doc.id);
          await updateDoc(productRef, {
            model: newName,
          });
        }
      });
    }
  }
  catch (e) {
    status = false;
  }
  return status;
}