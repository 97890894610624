import React, { useState } from 'react';

/* Sections */
/*
import Resumen from './Sections/Resumen';
import FiltradoBusqueda from './Sections/FiltradoBusqueda';
*/
import Vendidos from './Sections/Vendidos';

/* API */
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";


function Ventas() {

    const [user, setUser] = useState(null);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            setUser(user);
        }
    });

    if (user) {
        return (
            <div>
                { /*
                    <Resumen />
                    <FiltradoBusqueda />
                */}
                <Vendidos />
            </div>
        );
    }
    else return null;
}

export default Ventas;