import React, { useState } from 'react';

import FiltradoBusqueda from 'views/Administrador/Ventas/Sections/FiltradoBusqueda.js';
import Articulos from './Sections/Articulos';

/* API */
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";

function Publicaciones() {

    const [user, setUser] = useState(null);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            setUser(user);
        }
    });

    const [searchValue, setSearchValue] = useState('');
    const [arraySearch, setArraySearch] = useState([]);

    if (user) {
        return (
            <div>
                <FiltradoBusqueda
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    arraySearch={arraySearch}
                    setArraySearch={setArraySearch}
                />
                <Articulos searchValue={searchValue} arraySearch={arraySearch} />
            </div>
        );
    }
    else return null;
}

export default Publicaciones;
