import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SecurityIcon from '@material-ui/icons/Security';

export default function BestSellerSection(){

  return(
    <div 
      style={{
        marginRight: '5%',
        marginLeft: '5%',
        paddingBottom: '20px',
      }}
    >
      <Paper variant="outlined" style={{height: '100%', borderWidth: '1px', borderColor: 'black',}}>
        <div 
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingTop: '20px',
            paddingBottom: '30px',
            height: '80%',
          }}  
        >
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '-50px', marginLeft: '-20px', alignItems: 'center' }}>
            <PaymentIcon style={{height: '100px', width: '100px'}} viewBox='0 0 24 24' />
            <div style={{fontSize: '30px'}}> 
              Pago con <br /> tarjeta
            </div>
          </div>
          <Divider orientation="vertical" flexItem style={{width: '2px', backgroundColor: '#d7d7d7'}} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <LocalShippingIcon style={{height: '100px', width: '100px'}} viewBox='0 0 24 24' />
            <div style={{fontSize: '30px'}}> 
              Envío gratis
            </div>
          </div>
          <Divider orientation="vertical" flexItem style={{width: '2px', backgroundColor: '#d7d7d7'}} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <SecurityIcon style={{height: '100px', width: '100px'}} viewBox='0 0 24 24' />
            <div style={{fontSize: '30px'}}> 
              Seguro
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}