import React from 'react';

import ICONOS from 'helpers/iconos';
import { Grid } from '@mui/material';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Box } from '@material-ui/core';


function Nosotros() {
  var pageStyle = {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "100%",
  }

  var titleStyle = {
    borderBottom: '1px solid #000000',
  }

  var contentStyle = {
    padding: "10px"
  }

  const whatsapp_float = {
    width: "28px",
    height: "28px",
    backgroundColor: "#25d366",
    color: "#FFF",
    borderRadius: "50px",
    fontSize: "28px",
    boxShadow: "2px 2px 3px #999",
    marginRight: '10px'
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  };

  return (
    <div style={pageStyle}>

      <Grid container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >

        <Grid container xs={10} md={8}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={titleStyle}
        >
          <img
            src={ICONOS.AF} alt="AF"
            style={{ height: '50px', width: '50px', }}
          />
          <h2>¡CONTÁCTANOS!</h2>
        </Grid>

        <Grid container xs={10} md={8}
          align="left"
          style={contentStyle}
        >
          <div>
            <p>
              Contáctanos para encontrar los mejores productos para el
              mantenimiento preventivo de tu automóvil, tenemos años de
              experiencia en esta área y podemos ayudarle perfectamente a
              encontrar el kit de mantenimiento o refacción que se acople a
              sus necesidades y posibilidades.
            </p>

            <p>
              Nuestras ventas son totalmente online por lo que no contamos con
              puntos de venta físicos, pero te atenderemos perfectamente en
              cualquiera de nuestros medios digitales y podrás hacer tu compra
              de manera segura en esta página web.
            </p>

            <p>
              Nuestro equipo de trabajo es servicial, amigable y siempre dispuesto
              a responder las dudas que pudieras llegar a tener por lo que no dudes
              en contactarnos, para nosotros es un placer atenderte y ayudarte.
            </p>

            <p>
              El objetivo de AFINAZONE es asegurarnos de que puedas encontrar los
              productos que necesitas y se acoplen a tus posibilidades, nuestros
              expertos te podrán apoyar en resolver cualquier duda así que contáctanos
              con confianza en cualquiera de nuestras redes sociales o medios de contacto.
            </p>

            <Box style={{ display: 'flex' }}
              sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
              <div style={{ display: 'flex' }}>
                <a href='https://www.facebook.com/afinazonemx/' target="_blank" rel="noreferrer">
                  <FacebookIcon />
                </a>
                <Box
                  component="div"
                  style={{ fontWeight: 'bold', color: 'black', textDecoration: 'none', cursor: 'pointer', marginRight: '20px' }}
                  onClick={() => openInNewTab('https://www.facebook.com/afinazonemx/')}
                > Afinazone MX.</Box>
              </div>

              <div style={{ display: 'flex' }}>
                <a href='https://instagram.com/afinazonemx?utm_medium=copy_link' target="_blank" rel="noreferrer">
                  <InstagramIcon style={{ color: 'black' }} />
                </a>
                <Box
                  component="div"
                  style={{ fontWeight: 'bold', color: 'black', textDecoration: 'none', cursor: 'pointer', marginRight: '20px' }}
                  onClick={() => openInNewTab('https://www.facebook.com/afinazonemx/')}
                > Afinazone MX.</Box>
              </div>

              <div style={{ display: 'flex' }}>
                <a href='https://www.tiktok.com/@afinazonemx' target="_blank" rel="noreferrer">
                  <MusicNoteIcon style={{ color: 'red' }} />
                </a>
                <Box
                  component="div"
                  style={{ fontWeight: 'bold', color: 'black', textDecoration: 'none', cursor: 'pointer', marginRight: '20px' }}
                  onClick={() => openInNewTab('https://www.facebook.com/afinazonemx/')}
                > afinazonemx</Box>
              </div>

              <div style={{ display: 'flex' }}>

                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <a
                    href="https://wa.me/524613126256 "
                    style={whatsapp_float}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsIcon"
                  >
                    <WhatsAppIcon />
                  </a>
                </div>

                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                  <PhoneIcon style={{ marginRight: '5px' }} />
                  <span> 461-312-6256 </span>
                </div>
              </div>

            </Box>

            <p>
              Si requieres una respuesta lo más rápida posible te sugerimos enviar mensaje
              por Whatsapp, te responderemos en menos de 5 minutos en horario laboral.
            </p>

            <p>
              En caso de requerir una atención mucho más personalizada también ponemos
              a tu disposición el siguiente correo electrónico:
            </p>

            <a href="mailto: afinazone@outlook.com">afinazone@outlook.com</a>
          </div>
        </Grid>
      </Grid>

    </div >
  );
}

export default Nosotros;
