import { useEffect, useState } from 'react';

/* Material UI */
import { Grid } from '@material-ui/core';

/* Pictures */
import afinazone from 'assets/iconos/afinazone.png';

/* API */
import { getBrandsAPI } from 'utils/API/Brands';

import Box from '@mui/material/Box';


export default function Models(props) {

  const [availableModels, setAvailableModels] = useState(null);
  const [notAvailableModels, setNotAvailableModels] = useState(null);

  useEffect(() => {
    const go = async () => {
      var brands = await getBrandsAPI();
      var models;
      brands.forEach((doc) => {
        const brand = { ...doc.data() };
        if (brand.name === props.brand) {
          models = brand.models
        }
      });

      let checkModels = {};
      models.forEach((model) => {
        // doc.data() is never undefined for query doc snapshots
        checkModels[model] = false;
      });
      const products = props.products.filter(item => item.brand === props.brand);
      for (var product of products) {
        if (checkModels[product.model] !== undefined)
          checkModels[product.model] = true;
      }

      let avModels = [];
      let notAvModels = [];

      for (var check of Object.keys(checkModels)) {
        checkModels[check] ? avModels.push(check) : notAvModels.push(check);
      }

      avModels.sort();
      notAvModels.sort();
      setAvailableModels(avModels);
      setNotAvailableModels(notAvModels);
    }
    if (props.products !== null && props.brand !== null) {
      go();
    }
  }, [props.products, props.brand]);

  return (
    <Grid container spacing={3} >
      {availableModels !== null && availableModels.map((item, idx) => {
        return (
          <Grid key={idx} item xs={3} lg={3}>
            <div
              style={{
                width: '100%',
                paddingBottom: '10%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                componen='span'
                sx={{
                  fontSize: { xs: '12px', md: '20px' },
                }}
                style={{
                  marginBottom: '15px',
                }}
              >
                {item}
              </Box>
              <img
                src={afinazone}
                alt='marca'
                style={{ height: '80%', width: '80%', cursor: 'pointer' }}
                onClick={() => {
                  localStorage.setItem('model', item);
                  props.setOptionSection('product');
                  props.setModel(item);
                }}
                className="zoom-on-hover"
              />
            </div>
          </Grid>
        );
      })}
      <Box
        component={Grid}
        sx={{
          fontSize: { xs: '20px', md: '40px' },
        }}
        key={'not brands'} item sm={12}
      >
        Modelos no disponibles de momento
      </Box>
      {notAvailableModels !== null && notAvailableModels.map((item, idx) => {
        return (
          <Grid key={idx} item xs={3} lg={3}>
            <div
              style={{
                width: '100%',
                paddingBottom: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                componen='span'
                sx={{
                  fontSize: { xs: '12px', md: '20px' },
                }}
                style={{
                  marginBottom: '15px',
                }}
              >
                {item}
              </Box>
              <img
                src={afinazone}
                alt='marca'
                style={{ height: '80%', width: '80%', cursor: 'pointer' }}
                className="zoom-on-hover"
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}