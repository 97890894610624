import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { db } from "ConfigFirebase";
import { deleteProductAPI } from "./Product";

export async function addNewModelAPI(model) {

  let status = true;
  try {
    const modelRef = doc(db, 'brands', model.brand);
    const docSnap = await getDoc(modelRef);

    var models = docSnap.data().models;
    var modelsProducts = docSnap.data().modelsProducts;

    if (modelsProducts === undefined) {
      modelsProducts = new Array(models.length);
      modelsProducts.fill({});
    }

    let idx = models.indexOf(model.name);
    if (idx === -1) {
      models.push(model.name);
      modelsProducts.push({});
      idx = models.length - 1;
    }
    modelsProducts[idx][model.product] = true;

    await setDoc(modelRef, { models, modelsProducts }, { merge: true });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function deleteModelAPI(brand, model, product) {

  let status = true;
  try {
    const modelRef = doc(db, 'brands', brand);
    const docSnap = await getDoc(modelRef);

    var models = docSnap.data().models;
    var modelsProducts = docSnap.data().modelsProducts;
    if (models.includes(model)) {
      const idx = models.indexOf(model);
      const objectKeys = { ...modelsProducts[idx] };
      delete objectKeys[product];
      modelsProducts[idx] = objectKeys;
      if (Object.keys(objectKeys).length === 0) {
        models.splice(idx, 1);
        modelsProducts.splice(idx, 1);
      }

    }
    await setDoc(modelRef, { models, modelsProducts }, { merge: true });

    //Elimina productos con el modelo anterior
    const arrayProducts = await getDocs(collection(db, product));
    arrayProducts.forEach(async (Doc) => {
      let item = Doc.data();
      if (item.model === model) {
        await deleteProductAPI(Doc.id, product);
      }
    });

  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function getModelsAPI() {

  const clln = collection(db, "models");
  const querySnapshot = await getDocs(clln);
  return querySnapshot.docs.map(doc => doc.data());

}