import { useEffect, useState } from 'react';

import { Box, Divider, Button } from '@material-ui/core';

/* Routing */
import {
  Link
} from "react-router-dom";

/* Pictures */
import imageBroken from 'assets/img/image.svg';
import { getPreviewImageUrlFromProduct } from 'utils/API/Pictures';

const styleLink = {
  display: 'flex',
  textDecoration: 'none',
  color: 'black',
  cursor: 'pointer'
};

export default function FilterCatalogue({ products }) {

  const [showItems, setShowItems] = useState([]);

  useEffect(() => {

    async function go() {

      const arrayProducts = [...products];
      arrayProducts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      for (let pr of arrayProducts) {
        const url = await getPreviewImageUrlFromProduct(pr.id, pr.typeKey);
        pr.url = url;
      }
      setShowItems(arrayProducts);
    };
    go();

  }, [products]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component='div'
        sx={{
          fontSize: { xs: '20px', md: '50px' },
        }}
        style={{
          color: '#7F7F7F',
          fontWeight: '700',
          paddingBottom: '30px',
          paddingTop: '30px',
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        { /* Section to select a car */}
        {products.length > 0 ? 'Encontramos las siguientes opciones para ti' : 'Prueba con otra búsqueda'}
        <Divider
          style={{
            marginTop: '30px',
            backgroundColor: 'black',
            height: '1px',
            marginLeft: '15%',
            marginRight: '15%',
          }}
        />
      </Box>
      {
        showItems.map(item => {
          return (<ShowProduct item={item}></ShowProduct>);
        })
      }
    </div>
  );
}

function ShowProduct({ item }) {
  return (
    <>
      <Box
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <div
          style={{
            paddingBottom: '50px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '15%',
            paddingRight: '15%',
          }}
        >
          <Box
            component='div'
            sx={{
              flexDirection: { xs: 'row', md: 'row' },
            }}
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              component='div'
              to={`/Producto?product=${item.typeKey}&id=${item.id}`}
              target="_blank"
              style={styleLink}
              sx={{
                flexDirection: { xs: 'row', md: 'row' },
              }}
            >
              <Link
                to={`/Producto?product=${item.typeKey}&id=${item.id}`}
                target="_blank"
              >
                <Box
                  component='img'
                  src={item.url ?? imageBroken}
                  alt='car'
                  sx={{
                    width: { xs: '150px', md: '200px' },
                    height: { xs: '150px', md: '200px' },
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Link>
              {/* Description */}
              <div>
                <Box
                  component={Link}
                  to={`/Producto?product=${item.typeKey}&id=${item.id}`}
                  target="_blank"
                  sx={{
                    fontSize: { xs: '10px', md: '25px' },
                  }}
                  style={{
                    alignSelf: 'flex-start',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  <div style={{ textAlign: 'left', marginLeft: '10px' }}>
                    {item.name}
                  </div>
                  {item.disableOil &&
                    <div style={{ marginLeft: '10px' }}>
                      $ {item.price}
                    </div>
                  }
                  {!item.disableOil &&
                    item.liters.map((curr) => {
                      return (
                        <div style={{ marginLeft: '10px' }}>
                          $ {curr.price}, {curr.liters} L
                        </div>
                      );
                    })
                  }


                </Box>

              </div>
            </Box>
          </Box>
          <Link style={{ width: '20%', display: 'flex', justifyContent: 'end' }} to={`/Producto?product=${item.typeKey}&id=${item.id}`} target="_blank">
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '30px', height: '40px', fontWeight: '900' }}
            >
              Ver producto
            </Button>
          </Link>
        </div>
      </Box>

      <Box
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <div
          style={{
            paddingBottom: '50px',
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '10%',
            paddingRight: '10%',
          }}
        >
          <Box
            component='div'
            sx={{
              flexDirection: { xs: 'row', md: 'row' },
            }}
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              component='div'
              to={`/Producto?product=${item.typeKey}&id=${item.id}`}
              target="_blank"
              style={styleLink}
              sx={{
                flexDirection: { xs: 'row', md: 'row' },
              }}
            >
              <Link
                to={`/Producto?product=${item.typeKey}&id=${item.id}`}
                target="_blank"
              >
                <Box
                  component='img'
                  src={item.url ?? imageBroken}
                  alt='car'
                  sx={{
                    width: { xs: '150px', md: '200px' },
                    height: { xs: '150px', md: '200px' },
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Link>
              {/* Description */}
              <div>
                <Box
                  component={Link}
                  to={`/Producto?product=${item.typeKey}&id=${item.id}`}
                  target="_blank"
                  sx={{
                    fontSize: { xs: '10px', md: '25px' },
                  }}
                  style={{
                    alignSelf: 'flex-start',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  <div style={{ textAlign: 'left', marginLeft: '10px' }}>
                    {item.name}
                  </div>
                  {item.disableOil &&
                    <div style={{ marginLeft: '10px', fontSize: '20px' }}>
                      $ {item.price}
                    </div>
                  }
                  {!item.disableOil &&
                    item.liters.map((curr) => {
                      return (
                        <div style={{ marginLeft: '10px', fontSize: '20px' }}>
                          $ {curr.price}, {curr.liters} L
                        </div>
                      );
                    })
                  }

                </Box>

                <Link to={`/Producto?product=${item.typeKey}&id=${item.id}`} target="_blank">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: '10px', width: '25px', height: '25px', marginTop: '10px' }}
                  >
                    Ver
                  </Button>
                </Link>
              </div>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
}