/* Icons */
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function PicturesHandler(props) {

  const handleChange = e => {
    if (e.target.files) {
      e.preventDefault();

      let files = Array.from(e.target.files);

      files.forEach((file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          props.setImages(prev => [...prev, file]);
          props.setPreviewImages(prev => [...prev, reader.result]);
          props.setGrayFilter(prev => [...prev, '0']);
        }
        reader.readAsDataURL(file);
      });
    }
  };

  const iconMove = {
    cursor: 'pointer',
  };

  function handleMovePicture(index, movement) {
    if ((index === 0 && movement === 'back') || (index === props.images.length - 1 && movement === 'front'))
      return;
    const newImages = [...props.images];
    const newPreviewImages = [...props.previewImages];
    const newGrayFilter = [...props.grayFilter];

    var swap = index + (movement === 'front' ? 1 : -1);
    newImages[swap] = props.images[index];
    newImages[index] = props.images[swap];
    newPreviewImages[swap] = props.previewImages[index];
    newPreviewImages[index] = props.previewImages[swap];
    newGrayFilter[swap] = props.grayFilter[index];
    newGrayFilter[index] = props.grayFilter[swap];

    props.setImages(newImages);
    props.setPreviewImages(newPreviewImages);
    props.setGrayFilter(newGrayFilter);
  }

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <h1> Da click para agregar más fotos</h1>
        <PhotoCameraIcon style={{ fontSize: '50px', marginLeft: '10px', marginTop: '15px' }} />
        <input type='file' multiple="multiple" accept="image/*" onChange={handleChange} />
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {props.previewImages.map((preview, index) => {
          return (
            <div key={'picture' + index} style={{ display: 'flex', flexDirection: 'column' }}>
              <HighlightOffIcon onClick={() => {
                if (!(props.images[index] instanceof File)) {
                  props.setGrayFilter((prev) => {
                    const newArray = [...prev];
                    newArray[index] = newArray[index] === '0' ? '100' : '0';
                    return newArray;
                  });
                }
                else {
                  props.setPreviewImages((prev) => {
                    const newArray = [...prev];
                    newArray.splice(index, 1);
                    return newArray;
                  });
                  props.setImages((prev) => {
                    const newArray = [...prev];
                    newArray.splice(index, 1);
                    return newArray;
                  });
                }
              }} />
              <img
                src={preview}
                alt="brokenImage"
                style={{
                  filter: `grayscale(${props.grayFilter[index]}%)`,
                  border: props.grayFilter[index] === '100' ? '2px solid red' : null,
                  width: "200px",
                  height: "200px",
                  marginRight: '30px',
                }}
              />
              <div style={{ padding: '15px' }}>
                <ArrowBackIosIcon style={iconMove} onClick={() => handleMovePicture(index, 'back')} />
                <ArrowForwardIosIcon style={iconMove} onClick={() => handleMovePicture(index, 'front')} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}