
import { useState, useEffect } from "react";
import CurrencyFormat from 'react-currency-format';

/* Routing */
import {
  Link
} from "react-router-dom";

/* Icons */
import DeleteIcon from '@material-ui/icons/Delete';
import imageBroken from 'assets/img/image.svg';

/* Material-UI */
import TextField from '@mui/material/TextField';

/* API */
import { getProductAPI } from "utils/API/Product";
import { getPreviewImageUrlFromProduct } from "utils/API/Pictures";
import { Box } from "@material-ui/core";


const card = {
  padding: '15px',
  backgroundColor: '#d7d7d7',
  border: '1px solid #797979',
  borderRadius: '15px',
  fontSize: '20px',
  fontWeight: '300',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const imgStyle = {
  cursor: 'pointer',
  width: '100px',
  height: '100px',
};

const titleStyle = {
  fontWeight: '500',
  fontSize: '20px',
  marginBottom: '5px',
};
const titleStyleMobile = {
  fontWeight: '900',
  fontSize: '13px',
  marginBottom: '5px',
};

const descriptionStyle = {
  color: '#7F7F7F',
  fontWeight: '500',
  fontSize: '20px',
};
const descriptionStyleMobile = {
  color: '#7F7F7F',
  fontWeight: '900',
  fontSize: '15px',
};

const infoContainer = {
  marginLeft: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100px',
};

const productContainer = {
  alignItems: 'center',
  margin: '5px',
};

const productContainerMobile = {
  alignItems: 'center',
  margin: '5px',
  fontSize: '15px',
};

const actionsContainer = {
  display: 'flex',
  alignItems: 'center',
  margin: '5px',
  justifyContent: 'space-between',
};

const quantity = {
  backgroundColor: 'white',
  marginLeft: '10px',
  width: '60px',
}

const verticalLine = {
  width: '1px',
  height: '50px',
  marginLeft: '20px',
  marginRight: '20px',
  backgroundColor: 'black',
}

export default function ShoppingCarProduct({ id, type, description, price, amount, updateAmount, deleteItem }) {

  useEffect(() => {

    async function go() {
      if (!(id === null && type === null)) {
        var product = await getProductAPI(id, type);
        if (product !== null) {
          var url = await getPreviewImageUrlFromProduct(id, type);
          setTitle(product.name);
          setUrl(url);
          console.log(url);
        }
      }
    };

    go();

  }, [id, type]);

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState(null);

  return (
    <div style={card}>
      <div style={{ display: 'flex', ...productContainer }}>
        {url === null &&
          <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
            <img
              src={imageBroken}
              alt='car'
              style={imgStyle}
            />
          </Link>
        }
        {url !== null &&
          <Link to={`/Producto?product=${type}&id=${id}`} target="_blank">
            <img
              src={url}
              alt='car'
              style={imgStyle}
            />
          </Link>
        }
        <div style={infoContainer}>
          <Box sx={{ display: { xs: 'none', md: 'block' } }} style={titleStyle}> {title} </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }} style={titleStyleMobile}> {title} </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }} style={descriptionStyle}> {description} </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }} style={descriptionStyleMobile}> {description} </Box>
        </div>
      </div>

      <Box sx={{ flexDirection: { xs: 'column', md: 'row' } }} style={actionsContainer} >
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={productContainer}>
          Cantidad:
          <TextField
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            size='small' style={quantity} value={amount} onChange={(e) => updateAmount(e.target.value)} />
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} style={productContainerMobile}>
          Cantidad:
          <TextField
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            size='small' style={quantity} value={amount} onChange={(e) => updateAmount(e.target.value)} />
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'flex' } }} style={productContainer} >
          <Box sx={{ display: { xs: 'none', md: 'block' } }} style={titleStyle}> $ <CurrencyFormat value={parseFloat(price * amount).toFixed(2)} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }} style={titleStyleMobile}> $ <CurrencyFormat value={parseFloat(price * amount).toFixed(2)} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </Box>
          <div style={verticalLine} />
          <DeleteIcon
            style={{ cursor: 'pointer' }}
            onClick={deleteItem} />
        </Box>
      </Box>
    </div>
  );
}