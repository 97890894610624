
import { useQuery, PRODUCT } from 'helpers/helpers';
import { useState, useEffect } from 'react';

/* API */
import { getAllProductsAPI } from 'utils/API/Product';

/* Sections */
import FilterCatalogue from './FilterCatalogue';


export default function FilterSearchProducts() {

  let query = useQuery();

  const [searchedValue, setSearchedValue] = useState(null);
  const [listOfAllProducts, setlistOfAllProducts] = useState([]);
  const [products, setProducts] = useState([]);


  async function getAllProducts() {
    for (const product in PRODUCT) {
      const listOfProducts = await getAllProductsAPI(product);
      listOfProducts.forEach(async (doc) => {

        setlistOfAllProducts(prev =>
          [...prev, { 
            id: doc.id, 
            name: doc.data().name, 
            type: PRODUCT[product], 
            typeKey: product,
            disableOil: doc.data().disableOil,
            price: doc.data().price,
            liters: doc.data().liters ?? [],
          }]);
      })
    }
  };

  function compareProduct(a, b) {
    return a.toLowerCase().includes(b.toLowerCase());
  }

  useEffect(() => {
    setSearchedValue(query.get('filterValue'));
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearchedValue(query.get('filterValue'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchedValue]);

  useEffect(() => {
    if (searchedValue) {
      const filterOptions = listOfAllProducts.filter(item => compareProduct(item.name, searchedValue));
      setProducts(filterOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfAllProducts]);


  return (
    <div>
      <FilterCatalogue products={products}></FilterCatalogue>
    </div>
  );

}