/* Icons */
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CurrencyFormat from 'react-currency-format';

import Box from '@mui/material/Box';

const container = {
    paddingLeft: '15px',
    marginBottom: '15px',
};

const card = {
    width: "100%",
    padding: '15px',
    backgroundColor: '#d7d7d7',
    border: '1px solid #797979',
    borderRadius: '15px',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '300',
};

const head = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px',
    paddingBottom: '10px',
    fontWeight: 'bold',
}

const row = {
    display: 'flex',
    margin: '5px',
    paddingBottom: '10px',
}

const rowMobile = {
    display: 'flex',
    margin: '5px',
    paddingBottom: '10px',
    justifyContent: 'space-between'
}

const rowMini = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px',
    fontSize: '15px',
    width: '50%',
};

const rowMiniMobile = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px',
    fontSize: '12px',
};

const column = {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px',
}

const nombre = {
    width: '60%'
}

const precioUnitario = {
    width: '15%',
}

const cantidad = {
    width: '10%',
}

const totalArticulo = {
    width: '15%',
}


export default function PayInfo({ items, price }) {
    return (
        <>
            <Box
                component='div'
                sx={{ display: { xs: 'none', md: 'flex' } }}
                style={container}>
                <div style={card}>
                    <div style={head}>
                        <div style={nombre}> Articulo </div>
                        <div style={precioUnitario}> Precio Unit. </div>
                        <div style={cantidad}> Cant. </div>
                        <div style={totalArticulo}> Precio </div>
                    </div>
                    <hr />
                    {items.map(item => (
                        <div style={row}>
                            <div style={nombre}> {item.name} {item.description !== '' ? '(' + item.description + ')' : ''} </div>
                            <div style={precioUnitario}> $ <CurrencyFormat value={item.price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                            <div style={cantidad}> {item.amount} </div>
                            <div style={totalArticulo}> $ <CurrencyFormat value={Number((item.price * item.amount).toFixed(2))} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                        </div>
                    ))}

                    <hr />
                    <div style={head}>
                        <div style={nombre}> Subtotal </div>
                        <div style={precioUnitario}>  </div>
                        <div style={cantidad}>  </div>
                        <div style={totalArticulo}> $ <CurrencyFormat value={price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                    </div>
                    <LocalShippingIcon style={{ fontSize: '70px' }} />
                    <div style={row}>
                        <div style={nombre}> Envío </div>
                        <div style={precioUnitario}>  </div>
                        <div style={cantidad}>  </div>
                        <div style={totalArticulo}> {price >= 549 ? '¡Gratis!' : '$ 149.00'} </div>
                    </div>
                    <div style={rowMini}>
                        Envío estándar
                    </div>
                    <div style={rowMini}>
                        Tiempo de entrega estimado es de 2 a 5 días hábiles según el domicilio de entrega
                    </div>

                    <hr />
                    <div style={head}>
                        <div style={nombre}> TOTAL A PAGAR </div>
                        <div style={precioUnitario}>  </div>
                        <div style={cantidad}>  </div>
                        <div style={totalArticulo}> $ <CurrencyFormat value={parseFloat(price) + parseFloat(price >= 549 ? 0 : 149)} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                    </div>

                </div>
            </Box>
            <Box
                component='div'
                sx={{ display: { xs: 'flex', md: 'none' } }}
                style={{ marginBottom: '15px' }}
            >
                <div style={card}>
                    <div style={head}>
                        <div style={nombre}> Articulos</div>
                    </div>
                    <hr />
                    {items.map(item => (
                        <>
                            <div style={column}>
                                <div style={{ marginBottom: '10px' }}> {item.name} {item.description !== '' ? '(' + item.description + ')' : ''} </div>
                                <div> cantidad: {item.amount} </div>
                                <div> precio por unidad: $ <CurrencyFormat value={item.price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                                <div> precio $ <CurrencyFormat value={Number((item.price * item.amount).toFixed(2))} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                            </div>
                            <hr />
                        </>
                    ))}
                    <div style={head}>
                        <div> Subtotal </div>
                        <div> $ <CurrencyFormat value={price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                    </div>
                    <LocalShippingIcon style={{ fontSize: '70px' }} />
                    <div style={rowMobile}>
                        <div> Envío </div>
                        <div> {price >= 549 ? '¡Gratis!' : '$ 149.00'} </div>
                    </div>
                    <div style={rowMiniMobile}>
                        Envío estándar
                    </div>
                    <div style={rowMiniMobile}>
                        Tiempo de entrega estimado es de 2 a 5 días hábiles según el domicilio de entrega
                    </div>
                    <hr />
                    <div style={head}>
                        <div> TOTAL A PAGAR </div>
                        <div> $ <CurrencyFormat value={parseFloat(price) + parseFloat(price >= 549 ? 0 : 149)} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </div>
                    </div>

                </div>
            </Box>
        </>
    );
}