/* Material UI */
import {
  Chip,
} from "@material-ui/core";

export default function ChipContainer(props) {

  return (
    <div>
      {props.chipData.map((item, index) => {
        return (
            <Chip
              key={index}
              style={{ margin: '5px' }}
              label={item.text}
              variant="outlined"
              onDelete={() => {props.handleDelete(index);}}
              color={item.color}
            />
        );
      })}
    </div>
  );
}