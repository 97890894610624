import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "ConfigFirebase";

export async function getNumber() {
  const docRef = doc(db, 'numeros', 'numero_actual');
  const docSnap = await getDoc(docRef);

  let currentNumber = 0;

  if (docSnap.exists()) {
    currentNumber = docSnap.data().numero;
  } else {
    console.log("No such document!");
  }

  return currentNumber;
}

export async function incrementAndSaveNumber() {
  let status = true;

  try {
    const currentNumber = await getNumber();

    const newNumber = currentNumber + 1;

    const updatedNumber = {
      numero: newNumber
    };

    const numberRef = doc(db, 'numeros', 'numero_actual');
    await setDoc(numberRef, updatedNumber);
  }
  catch (e) {
    status = false;
  }

  return status;
}