import { COLORS } from 'utils/Constants/Colors';

export default function PaginaEnServicio(){
  return (
      <div
        style={{
          background: `linear-gradient(to bottom, ${COLORS.primary} 40%, ${COLORS.secondary} 80%)`,
          color: 'white',
          height: '40vh',
          fontColor: 'black',
          fontSize: '70px',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Montserrat',
          opacity: '0.7',
        }}
      >
        <div style={{
          width: '80%',
        }}>
          Seguimos trabajando en ello para ti
        </div>
      </div>
  );
}