export default function CategorySelector({ options, handleSelected }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '40px',
        marginBottom: '80px',
        marginRight: '10%',
        marginLeft: '10%',
        paddingBottom: '30px',
        paddingLeft: '5%',
        paddingRight: '5%',
      }}
    >
      {options.map((item) => {
        return (
          <div
            style={{
              height: '100px',
              width: '30%',
              border: '10px solid #b3b3b3',
              backgroundColor: '#d6d6d6',
              marginLeft: '60px',
              marginRight: '60px',
              marginTop: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '30px',
              cursor: 'pointer'
            }}
            onClick={() => handleSelected(item.value)}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
}