import { useState } from "react";

/* Material UI */
import {
  Modal,
  Input,
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

/* API */
import { editBrandModel } from 'utils/API/Brands';
import { editBrandOil } from 'utils/API/BrandsOil';
import { editOilType } from 'utils/API/OilTypes';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function EditModel(props) {

  const classes = useStyles();

  const [newName, setNewName] = useState('');

  const [errorSubmit, setErrorSubmit] = useState(false);

  async function handleNewItem(e) {
    e.preventDefault();
    setErrorSubmit(false);
    if (newName === '')
      return;

    let success = true;
    /* Actualizamos las marcas por agregar */
    if (props.typeOfItem === 'model')
      success = await editBrandModel(props.brandSelected, props.modelSelected, newName);
    else if (props.typeOfItem === 'brandOil')
      success = await editBrandOil(props.brandOilSelected, newName);
    else if (props.typeOfItem === 'oilType')
      success = await editOilType(props.typeOfOilSelected, newName);


    if (success) {
      alert('Datos actualizados correctamente');
      props.handleClose();
      props.updateBrands();
    }
    else alert('No se pudo actualizar los datos');
  }

  if (props.typeOfItem === 'model') {
    return (
      <div>
        <Modal
          open={props.open}
          onClose={props.handleClose}
        >
          <div className={classes.paper}>
            <div> Marca: {props.brandSelected}  </div>

            <div> Nombre actual del modelo: {props.modelSelected} </div>

            <div> Cambiar a: </div>

            <form onSubmit={handleNewItem}>
              <Input
                value={newName}
                onChange={e => { e.preventDefault(); setNewName(e.target.value); }}
                error={errorSubmit}
              />
            </form>
          </div>
        </Modal>
      </div>
    );
  }
  else if (props.typeOfItem === 'oilType') {
    return (
      <div>
        <Modal
          open={props.open}
          onClose={props.handleClose}
        >
          <div className={classes.paper}>
            <div> Nombre actual del tipo de aceite : {props.typeOfOilSelected} </div>

            <div> Cambiar a: </div>

            <form onSubmit={handleNewItem}>
              <Input
                value={newName}
                onChange={e => { e.preventDefault(); setNewName(e.target.value); }}
                error={errorSubmit}
              />
            </form>
          </div>
        </Modal>
      </div>
    );
  }
  else if (props.typeOfItem === 'brandOil') {
    return (
      <div>
        <Modal
          open={props.open}
          onClose={props.handleClose}
        >
          <div className={classes.paper}>
            <div> Nombre actual de la marca : {props.brandOilSelected} </div>

            <div> Cambiar a: </div>

            <form onSubmit={handleNewItem}>
              <Input
                value={newName}
                onChange={e => { e.preventDefault(); setNewName(e.target.value); }}
                error={errorSubmit}
              />
            </form>
          </div>
        </Modal>
      </div>
    );
  }
  else return null;
}