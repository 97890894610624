import React from 'react';

/* Material UI */
import Grid from '@material-ui/core/Grid';

/* Router */
import {
  useHistory,
} from "react-router-dom";

import ICONOS from 'helpers/iconos';
import { COLORS } from 'utils/Constants/Colors';

import Promotion from 'views/ModalPromotion/ModalPromotion';

import Box from '@mui/material/Box';

function ProductSections() {

  /* Funcitons from routing */

  let history = useHistory();

  function handleClickRoute(route) {
    history.push(route);
  }

  /* Styles */
  const textIcon = {
    fontSize: '25px',
    color: COLORS.back,
    fontWeight: '800',
    width: '80%',
  };
  const textMedium = {
    fontSize: '22px',
    fontWeight: '800',
    width: '50%'
  };

  const textSmall = {
    fontSize: '18px',
    fontWeight: '800',
    width: '30%'
  };


  const textMobile = {
    fontSize: '18px',
    color: COLORS.back,
    fontWeight: '600',
  };

  return (
    <div>

      <Box
        component='div'
        sx={{ display: { xs: 'none', md: 'flex' } }}
        style={{
          margin: '5% 10%',
          height: '100%',
          widht: '100%',
        }}
      >
        {/* Primera Sección */}
        <div
          style={{
            width: '30%',
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute('Categoria?section=KAT')}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img
              src={ICONOS.KAT} alt="Kit de Afinación de Transmisión"
              style={{ width: '100%', }}
            />
            <div style={textIcon}> Kit de Afinación de Transmisión</div>
          </div>
        </div>

        {/* Segunda Sección */}
        <div
          style={{
            width: '70%',
            display: 'flex',
          }}
        >
          { /* 2-1 subsección */}
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute('Categoria?section=KAM')}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  src={ICONOS.KAM} alt="Kit de Afinación para Motor"
                  style={{ width: '40%' }}
                />
                <div style={{ ...textMedium }}> Kit de Afinación para Motor </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}

            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute('Categoria?section=Ac')}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  src={ICONOS.Ac} alt="Aceite"
                  style={{ width: '40%' }}
                />
                <div style={{ ...textMedium }}> Aceites </div>
              </div>
            </div>
          </div>

          { /* 2-2 subsección */}
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute('Categoria?section=Enf')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '85%'
                }}
              >
                <img
                  src={ICONOS.Enf} alt="Enfriadores"
                  style={{ width: '30%' }}
                />
                <div style={{ ...textMedium }}> Enfriadores </div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}

            >
              <div
                class="zoom-on-hover"
                onClick={() => handleClickRoute('Categoria?section=Cart')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '85%'
                }}
              >
                <img
                  src={ICONOS.Cart} alt="Cárter"
                  style={{ width: '30%' }}
                />
                <div style={{ ...textMedium }}> Cárter </div>
              </div>
            </div>
          </div>


        </div>

      </Box>

      <Box
        component='div'
        sx={{ display: { xs: 'none', md: 'flex' } }}
        style={{
          margin: '5% 10%',
          height: '100%',
          widht: '100%',
        }}
      >
        {/* Primera Sección */}
        <div
          style={{
            width: '20%',
            marginRight: '5%',
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute('Categoria?section=CaAc')}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={ICONOS.CaAc} alt="Cambio de Aceite para Motor"
              style={{ width: '50%', }}
            />
            <div style={{ ...textSmall, width: '50%' }}> Cambio de Aceite para Motor</div>
          </div>
        </div>

        {/* Segunda Sección */}
        <div
          style={{
            width: '20%',
            marginRight: '5%',
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute('Categoria?section=Bj')}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={ICONOS.Bj} alt="Bujías"
              style={{ width: '50%', }}
            />
            <div style={textSmall}> Bujías </div>
          </div>
        </div>

        {/* Tercera Sección */}
        <div
          style={{
            width: '20%',
            marginRight: '5%',
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute('Categoria?section=Flt')}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={ICONOS.Flt} alt="Filtros"
              style={{ width: '50%', }}
            />
            <div style={textSmall}> Filtros </div>
          </div>
        </div>

        {/* Cuarta Sección */}
        <div
          style={{
            width: '20%',
          }}
        >
          <div
            class="zoom-on-hover"
            onClick={() => handleClickRoute('Categoria?section=Accs')}
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src={ICONOS.Accs} alt="Accesorios"
              style={{ width: '50%', }}
            />
            <div style={textSmall}> Accesorios </div>
          </div>
        </div>
      </Box>

      <Box
        component='div'
        sx={{ display: { xs: 'block', md: 'none' } }}
        style={{
          marginLeft: '5%',
          marginRight: '5%',
        }}>
        <Grid
          container style={{
            paddingTop: '20px',
            marginBottom: '10px',
          }}>
          <Promotion />

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAT') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=KAT')}>
                <img
                  src={ICONOS.KAT} alt="Kit de Afinación de Transmisión"
                  style={{ height: '100%', width: '100%', }}
                />
                <div style={textMobile}> Kit de Afinación de Transmisión</div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=KAM')}>
                <img
                  src={ICONOS.KAM} alt="Kit de Afinación para Motor"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Kit de Afinación para Motor</div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=CaAc')}>
                <img
                  src={ICONOS.CaAc} alt="Cambio de Aceite para Motor"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Cambio de Aceite para Motor</div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Ac')}>
                <img
                  src={ICONOS.Ac} alt="Cambio de Aceite para Motor"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Aceites </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Flt')}>
                <img
                  src={ICONOS.Flt} alt="Filtros"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Filtros </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Accs')}>
                <img
                  src={ICONOS.Accs} alt="Accesorios"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Accesorios </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Bj')}>
                <img
                  src={ICONOS.Bj} alt="Bujías"
                  style={{ height: '100%', width: '100%' }}
                />
                <div style={textMobile}> Bujías </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                paddingTop: '20px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Enf')}>
                <img
                  src={ICONOS.Enf} alt="Enfriadores"
                  style={{ height: '75%', width: '75%' }}
                />
                <div style={{...textMobile, marginTop: '20px'}}> Enfriadores </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={6}
          >
            {/* Este div solo define la posición de un cajón que corresponde al borde*/}
            <div
              style={{
                margin: '10px',
                paddingTop: '10px',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            // onClick={() => { handleClickRoute('Categoria?section=KAM') }}
            >
              <div class="zoom-on-hover" onClick={() => handleClickRoute('Categoria?section=Cart')}>
                <img
                  src={ICONOS.Cart} alt="Cárter"
                  style={{ height: '75%', width: '75%' }}
                />
                <div style={textMobile}> Cárter </div>
              </div>
            </div>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}

export default ProductSections;