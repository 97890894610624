import { stack as Menu } from 'react-burger-menu'
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';

export default function BurguerMenu() {

  const styles = {
    bmBurgerButton: {
      position: 'sticky',
      width: '36px',
      height: '36px',
      padding: '10px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
    },
    bmMenuWrap: {
      position: 'absolute',
      height: '100vh',
      top: '0px',
      left: '0px',
      width: '50%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: 'white',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
    }
  };

  const itemStyle = {
    fontSize: '20px',
    marginBottom: '20px',
    cursor: 'pointer'
  };

  let history = useHistory();

  function handleClickRoute(route) {
    history.push(route);
  };

  return (
    <Menu
      styles={ styles } 
      customBurgerIcon={<MenuIcon />}
      customCrossIcon={<CancelIcon />}
    >
      <div style={itemStyle} onClick={() => handleClickRoute('')}> Inicio </div>
      <div style={itemStyle} onClick={() => handleClickRoute('Tips')}> Tips Vehículo</div>
      <div style={itemStyle} onClick={() => handleClickRoute('EnviosyPagos')}> Envíos y Pagos </div>
      <div style={itemStyle} onClick={() => handleClickRoute('Nosotros')}> Nosotros </div>
      <div style={itemStyle} onClick={() => handleClickRoute('Contacto')}> Contacto </div>
    </Menu>
  );
}