import ShoppingCarProduct from "components/ShoppingCarProduct/ShoppingCarProduct";

/* Helpers */
import { convertToShoppingCartElement, saveShoppingCar } from 'helpers/helpers';


const container = {
  padding: '15px',
};



export default function Products({ items, updateItems }) {

  function updateAmount(value, id, description) {
    var shoppingCar = convertToShoppingCartElement();
    var newShoppingCar = shoppingCar.map(item => {
      if (item.id === id && item.description === description) {
        return {
          ...item,
          amount: value > 0 && !isNaN(value) ? value : '',
        }
      }
      else return item;
    });
    updateItems(newShoppingCar);
    saveShoppingCar(newShoppingCar);
  }

  function deleteItem(id, description) {
    var shoppingCar = convertToShoppingCartElement();
    var newShoppingCar = shoppingCar.filter(item => {
      return (!(item.id === id && item.description === description));
    });
    updateItems(newShoppingCar);
    saveShoppingCar(newShoppingCar);
  }

  return (
    <div style={container}>
      {items !== null && items.map((item, idx) => {
        return (
          <div key={idx} style={{ marginBottom: '30px' }}>
            <ShoppingCarProduct
              id={item.id}
              type={item.type}
              description={item.description}
              price={item.price}
              amount={item.amount}
              updateAmount={(v) => updateAmount(v, item.id, item.description)}
              deleteItem={() => deleteItem(item.id, item.description)}
            />
          </div>
        );
      })}
    </div>
  );
}