import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ICONOS from "helpers/iconos";
import { Button } from "@mui/material";
import "./Tips.css"; // Importa el archivo CSS de estilos

import { getAllPapers } from "utils/API/ArticulosAPI";
import { getPreviewImageUrlFromPaper } from "utils/API/Pictures";

export default function Tips() {
  const [papers, setPapers] = useState([]);

  useEffect(function () {
    async function go() {
      const papers = await getAllPapers();
      for (const paper of papers) {
        paper.url = await getPreviewImageUrlFromPaper(paper.id);
      }
      
      papers.sort((a, b) => a.order - b.order);
      setPapers(papers);
    }
    go();
  }, []);

  return (
    <div className="tips-container">
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #000000',
        marginBottom: '50px',
      }}>
        <img src={ICONOS.AF} alt="AF" className="header-icon" />
        <h2>Tips para tu vehículo</h2>
      </div>
      <div className="papers-container">
        {papers.map((paper) => (
          <PreviewPaper key={paper.id} paper={paper} />
        ))}
      </div>
    </div>
  );
}

function PreviewPaper({ paper }) {
  const history = useHistory();

  return (
    <div className="preview-paper" onClick={() => history.push(`/Paper?id=${paper.id}`)}>
      <img src={paper.url} alt="url" className="picture" />
      <div className="text-container">
        <div className="title">{paper.title}</div>
        <div className="description">{paper.description}</div>
        <Button variant="contained" className="view-button">
          Ver completo
        </Button>
      </div>
    </div>
  );
}
