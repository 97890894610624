import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { uploadPicture } from "utils/API/Pictures";
import { Button } from "@mui/material";
import {
  addNewPaper,
  getAllPapers,
  uploadPDF,
} from "utils/API/ArticulosAPI";
import { v4 as uuidv4 } from 'uuid';

export default function TipsRegistro() {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionGAllery, setDescriptionGallery] = useState("");
  const [pdf, setPDF] = useState(null);

  const handleChange = (e) => {
    if (e.target.files) {
      e.preventDefault();

      let reader = new FileReader();

      let file = e.target.files[0];
      reader.onloadend = () => {
        setImage(file);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePDF = (e) => {
    if (e.target.files) {
      e.preventDefault();
      let file = e.target.files[0];
      setPDF(file);
    }
  };

  async function handleUpload(e) {
    e.preventDefault();

    const uuid = uuidv4();

    if (image === null || title === "" || description === "" || pdf === null) {
      alert("Rellena todos los campos");
      return;
    }

    const imagesUploadCorrectly = await uploadPicture(
      `articulo/${uuid}`,
      image
    );

    const path = `paper/${uuid}`;
    const paper = await uploadPDF(path, pdf);

    var order = await getAllPapers();
    order = order.length;

    const paperUpload = await addNewPaper({
      title,
      description,
      paper,
      descriptionGAllery,
      order,
    }, uuid);

    if (imagesUploadCorrectly && paperUpload && paper) {
      alert("El paper se subió correctamente");
    } else {
      alert("Hubo un error al intentar subir el paper");
    }
  }

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "700px" },
      }}
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleUpload}
    >
      <TextField
        id="title-paper"
        label="Título"
        color="primary"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextField
        id="description-paper"
        label="Descripción breve"
        color="primary"
        multiline
        rows={5}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <TextField
        id="description-gallery"
        label="Descripción galería"
        color="primary"
        multiline
        rows={5}
        value={descriptionGAllery}
        onChange={(e) => setDescriptionGallery(e.target.value)}
      />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div> Da click para agregar un archivo PDF</div>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleChangePDF}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div> Da click para agregar una foto</div>
        <PhotoCameraIcon
          style={{ fontSize: "50px", marginLeft: "10px", marginTop: "15px" }}
        />
        <input type="file" accept="image/*" onChange={handleChange} />
      </div>

      <img
        src={previewImage}
        alt="brokenImage"
        style={{
          width: "200px",
          height: "200px",
        }}
      />

      <Button variant="contained" style={{ width: "30px" }} type="submit">
        Subir
      </Button>
    </Box>
  );
}
