import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';

import logo from 'assets/logoprincipal.png'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalPromotion() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    const currentDate = new Date();
    handleClose();

    if (!lastVisit || lastVisit !== currentDate.getDate().toString()) {
      localStorage.setItem('lastVisit', currentDate.getDate());
      handleOpen();
    }

  }, []);

  const size = {
    height: '300px', 
    width: '300px',
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style, size}}>
          <img src={logo} style={size} alt="promocion" />
        </Box>
      </Modal>
    </div>
  );
}
