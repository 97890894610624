import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./Paper.css";
import { useQuery } from "helpers/helpers";
import { getPaper } from "utils/API/ArticulosAPI";
import ICONOS from "helpers/iconos";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [numPages]);

  return (
    <div>
      <div className="pdf-container">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <div key={`page_${index + 1}`} className="pdf-page-container">
              <Page
                pageNumber={index + 1}
                className="pdf-page"
                width={Math.min(windowWidth * 0.9, 900)}
              />
            </div>
          ))}
        </Document>
      </div>
      <div>
        <p className="page-counter">Páginas: {numPages}</p>
      </div>
    </div>
  );
};

const Paper = () => {
  let query = useQuery();
  const [paper, setPaper] = useState(null);

  useEffect(() => {
    async function go() {
      const paper = await getPaper(query.get("id"));
      setPaper(paper);
    }

    go();
  }, [query]);

  return (
    <div className="paper-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #000000",
          marginBottom: "50px",
          width: '80vw'
        }}
      >
        <img src={ICONOS.AF} alt="AF" className="header-icon" />
        <h2>Tips para tu vehículo</h2>
      </div>
      <h1 className="paper-title">{paper?.title}</h1>
      <PDFViewer file={paper?.paper} />
    </div>
  );
};

export default Paper;
