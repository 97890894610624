import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "ConfigFirebase";


import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export async function addNewPaper(body, uuid) {

  let status = true;
  try {
    const sellRef = doc(db, 'articulos', uuid);
    await setDoc(sellRef, body, { merge: true });
  }
  catch (e) {
    status = false;
  }
  return status;
}

export async function deletePaper(uuid) {
  let status = true;
  try {
    const paperRef = doc(db, "articulos", uuid);
    await deleteDoc(paperRef);
  } catch (e) {
    status = false;
  }
  return status;
}

export async function editPaper(uuid, updatedData) {
  let status = true;
  try {
    const paperRef = doc(db, "articulos", uuid);
    await updateDoc(paperRef, updatedData);
  } catch (e) {
    status = false;
  }
  return status;
}


export async function getAllPapers() {

  const querySnapshot = await getDocs(collection(db, 'articulos'));
  return querySnapshot.docs.map(doc => {
    return {...doc.data(), id: doc.id};
  });
}

export async function getPaper(uuid) {

  const docRef = doc(db, 'articulos', uuid);
  const docSnap = await getDoc(docRef);

  let data = null;

  if (docSnap.exists()) {
    data = docSnap.data();
  } else {
    console.log("No such document!");
  }
  return data;
}


export async function uploadPDF(path, pdf) {

  const storage = getStorage();
  const storageRef = ref(storage, path);
  var status = false;
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, pdf).then((snapshot) => {
    status = true;
  });

  if(status){
    await getDownloadURL(storageRef)
      .then((url) => {
        status = url;
      })
      .catch((error) => {
        alert('error al cargar pdf');
      });
  }

  return status;
}