import { useState } from 'react';

/* Routing */
import {
  useHistory
} from "react-router-dom";

/* Material UI */
import Card from '@mui/material/Card';

/* API */
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";

const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '85vh',
  widht: '100%',
};

const sectionsContainer = {
  display: 'flex',
};

const section = {
  width: '200px',
  height: '100px',
  margin: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

export default function PanelAdministracion() {

  /* Funcitons from routing */

  let history = useHistory();

  function handleClickRoute(route) {
    history.push(route);
  }

  const [user, setUser] = useState(null);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setUser(user);
    }
  });

  if (user) {
    return (
      <div style={container}>
        <h1> Escoge una sección </h1>
        <div style={sectionsContainer}>
          <Card style={section} onClick={() => handleClickRoute('/Registro')}>
            Registro de producto
          </Card>
          <Card style={section} onClick={() => handleClickRoute('/Ventas')}>
            Ventas
          </Card>
          <Card style={section} onClick={() => handleClickRoute('/Publicaciones')}>
            Publicaciones
          </Card>
          <Card style={section} onClick={() => handleClickRoute('/TipsRegistro')}>
            Registro Tips
          </Card>
          <Card style={section} onClick={() => handleClickRoute('/OrdenarTips')}>
            Ordenar tips
          </Card>
        </div>
      </div>
    );
  }
  else return null;
}