/* Routing */
import {
  useHistory
} from "react-router-dom";

import logo from 'assets/afinazoneTransparente.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import { COLORS } from 'utils/Constants/Colors';

export default function Footer() {

  let history = useHistory();


  return (
    <div
      style={{
        background: `linear-gradient(to bottom , ${COLORS.back} 40%, ${COLORS.primary} 100%)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '5%',
        paddingTop: '10px',
        paddingBottom: '40px',
      }}
    >
      <div
        style={{
          color: 'white',
          fontSize: '30px',
          paddingBottom: '15px',
        }}
      >
        TU KIT DE AFINACIÓN
        <img src={logo} alt={'logo'} style={{ height: '25px', marginLeft: '10px' }} />
      </div>
      <div
        style={{
          color: 'white',
          fontSize: '15px',
          paddingBottom: '15px',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{ cursor: 'pointer', marginRight: '5px' }}
          onClick={() => history.push('AvisoDePrivacidad')}
        >
          Aviso de privacidad
        </div> |  Políticas de compra  |  Términos y condiciones
      </div>
      <div
        style={{
          color: 'white',
          fontSize: '15px',
        }}
      >
        AFINAZONE  |  Todos los derechos reservados
        <a href='https://www.facebook.com/afinazonemx/' target="_blank" rel="noreferrer" style={{marginLeft: '10px'}}>
          <FacebookIcon style={{ color: 'white' }} />
        </a>
        <a href='https://instagram.com/afinazonemx?utm_medium=copy_link' target="_blank" rel="noreferrer">
          <InstagramIcon style={{ color: 'white' }} />
        </a>
        <a href='https://www.tiktok.com/@afinazonemx' target="_blank" rel="noreferrer">
          <MusicNoteIcon style={{ color: 'white' }} />
        </a>

      </div>
    </div>
  );
}