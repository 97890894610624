import React from 'react';
import imageBroken from 'assets/img/image.svg';

import Button from '@mui/material/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

/* Firebase */
import { getAllProductsAPI } from 'utils/API/Product';

import { addNewProduct, deleteProductAPI } from 'utils/API/Product';
import { getPreviewImageUrlFromProduct } from 'utils/API/Pictures';

/* Editar */
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

/* Routing */
import {
  useHistory,
  Link
} from "react-router-dom";
import CurrencyFormat from 'react-currency-format';


/* Agrega las opciones que aparecen al hacer click en los tres puntos */
var options = [
  'Editar',
  'Borrar',
]
const ITEM_HEIGHT = 48;

function Articulos({ searchValue, arraySearch }) {

  /* Variable con todos los Id's e informacion de los productos */
  const [products, setProducts] = React.useState([]);

  function compareProduct(a, b) {

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  /* Obtiene todos los productos */
  async function getAllProducts() {
    let data = [];

    let types = ['KAT', 'KAM', 'CaAc', 'Ac', 'Flt', 'Bj', 'Accs', 'Enf', 'Cart'];

    for (const productType of types) {
      const arrayProducts = await getAllProductsAPI(productType);
      arrayProducts.forEach(async (doc) => {
        // doc.data() is never undefined for query doc snapshots
        var url = null;
        await getPreviewImageUrlFromProduct(doc.id, productType)
          .then(r => url = r)
          .catch(e => console.log(e))
          .finally(() => {
            data.push({ ...doc.data(), url, id: doc.id, type: productType });
            setProducts([...data.sort(compareProduct)]);
          });
      });
    }

    setProducts(data.sort(compareProduct));
  }

  async function handleChecked(index, stock) {
    let status = await addNewProduct(products[index].id, { stock }, products[index].type);
    if (status) {
      setProducts(prev =>
        prev.map((item, idx) => {
          if (idx !== index) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          // Otherwise, this is the one we want - return an updated value
          return {
            ...item,
            stock,
          }
        })
      );
      alert('Stock actualizado');
    }
    else {
      alert('No se puede actualizar el stock');
    }
  }

  /* Inicializa productos a mostrar */
  React.useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '35px',
      margin: '150px',
      marginBottom: '20px',
      marginTop: '0px',
    }}>
      {products.map((item, index) => {

        const name = item.name;
        const isSubstring = name.toLowerCase().includes(searchValue.toLowerCase());

        if (!isSubstring)
          return null;
        if (arraySearch.length && arraySearch.indexOf(item.type) === -1)
          return null;

        return (
          <div
            id={'Product-' + index}
            key={index}
            style={{
              padding: '30px',
              marginBottom: '30px',
              width: '100%',
              backgroundColor: '#d7d7d7',
              border: '1px solid #797979',
              borderRadius: '15px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Link to={`/Producto?product=${item.type}&id=${item.id}`} target="_blank">
              <img
                src={item.url !== null ? item.url : imageBroken}
                alt='car'
                style={{
                  cursor: 'pointer',
                  width: '100px',
                  height: '100px',
                }}
              />
            </Link>
            <div style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <div style={{
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'left',
                marginLeft: '30px',
              }}>
                {item.name}
              </div>
              <div style={{
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'left',
                marginLeft: '30px',
              }}>
                {item.disableOil &&
                  <div style={{ marginLeft: '10px' }}>
                    $ <CurrencyFormat value={item.price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />
                  </div>
                }
                {!item.disableOil &&
                  item.liters.map((curr) => {
                    return (
                      <div style={{ marginLeft: '10px' }}>
                        $ <CurrencyFormat value={curr.price} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />
                        , {curr.liters} L
                      </div>
                    );
                  })
                }
              </div>

              <div style={{
                fontSize: '20px',
                textAlign: 'left',
                marginTop: '30px',
                marginLeft: '30px',
              }}>
                <div>
                  <Checkbox
                    checked={item.stock}
                    onChange={(e) => { e.preventDefault(); handleChecked(index, e.target.checked); }}
                    color="default" />
                  Stock disponible
                </div>
              </div>
            </div>
            <OptionsMenu uuid={item.id} product={item.type} getAllProducts={getAllProducts} />
          </div>
        );
      })}
    </div>
  );
}

export default Articulos;

function OptionsMenu({ product, uuid, getAllProducts }) {

  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);

  function handleRedirectToEdit(option) {
    if (option === 'Editar') {
      history.push(`/Registro?product=${product}&id=${uuid}`);
    }
    else if (option === 'Borrar') {
      setAlertDialogOpen(true);
    }
  }

  return (
    <div style={{
      width: '10%',
    }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon fontSize={'large'} />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={'edit-' + index + option}
            onClick={() => handleRedirectToEdit(option)}
            getAllProducts={getAllProducts}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <AlertDialog
        open={alertDialogOpen}
        setOpen={(value) => setAlertDialogOpen(value)}
        product={product}
        uuid={uuid}
        getAllProducts={getAllProducts}
      />
    </div>
  );
}


function AlertDialog({ open, setOpen, getAllProducts, product, uuid }) {

  const handleClose = () => {
    setOpen(false);
  };

  async function selectedOption(answer) {
    if (answer === 'yes') {
      let status = await deleteProductAPI(uuid, product);
      if (status) {
        alert('Se eliminó correctamente el producto');
        getAllProducts();
      }
      else {
        alert('no se ha podido eliminar el producto');
      }
    }
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Está seguro que desea borrar?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => selectedOption('yes')}>Si</Button>
          <Button onClick={() => selectedOption('no')} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}