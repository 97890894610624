import React from 'react';

/* Material UI */
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

/* Icons */
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

/* Helpers */
import { useQuery, validateField, validateNumber } from 'helpers/helpers';

/* Components */
import AddCarItem from 'components/AddCarItem/AddCarItem';
import EditModel from 'components/EditModel/EditModel';
import PicturesHandler from 'components/PicturesHandler/PicturesHandler';

/* API */
import { getBrandsAPI } from 'utils/API/Brands';
import { getOilTypesAPI } from 'utils/API/OilTypes';
import { getOilBrandsAPI } from 'utils/API/BrandsOil';
import { addNewProduct, getProductAPI } from 'utils/API/Product';
import { getImagesFromUpdateProduct, uploadPictures } from 'utils/API/Pictures';

/* UUID */
import { v4 as uuidv4 } from 'uuid';

export default function Form({ product }) {

  let query = useQuery();

  // getModalStyle is not a pure function, we roll the style only on the first render

  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [disableOil, setDisableOil] = React.useState(true);
  const [price, setPrice] = React.useState('1');
  const [liters, setLiters] = React.useState([{ liters: '1', price: '1' }]);
  const [brandSelected, setBrandSelected] = React.useState('');
  const [modelSelected, setModelSelected] = React.useState('');

  const [typeOfOilSelected, setTypeOfOilSelected] = React.useState('');
  const [brandOilSelected, setBrandOilSelected] = React.useState('');

  /* Controlamos las marcas y modelos */
  const [addCarItem, setAddCarItem] = React.useState(false);
  const [typeOfItem, setTypeOfItem] = React.useState('');
  const [editModel, setEditModel] = React.useState(false);

  const [images, setImages] = React.useState([]);
  const [previewImages, setPreviewImages] = React.useState([]);
  const [grayFilter, setGrayFilter] = React.useState([]);

  async function handleUpload() {

    let id = uuidv4();

    if (query.get('id')) {
      id = query.get('id');
    }

    const imagesUploadCorrectly = await uploadPictures(id, query.get('product'), images, grayFilter, previewImages);
    if (!imagesUploadCorrectly) {
      alert('imagenes cargadas incorrectamente');
      return;
    }
    var valid = validateField(name) &&
      validateField(description);

    if (product === 'Ac') {
      valid = validateField(brandOilSelected) &&
        validateField(typeOfOilSelected) &&
        valid;
    }
    else if (product !== 'Accs') {
      valid = valid &&
        validateField(brandSelected) &&
        validateField(modelSelected);
    }

    if (!disableOil) {
      for (const oil of liters) {
        valid = valid && validateNumber(oil.liters);
        valid = valid && validateNumber(oil.price);
        if (valid) {
          oil.liters = parseFloat(oil.liters);
          oil.price = parseFloat(oil.price);
        }
      }
    }
    else {
      valid = valid && validateNumber(price);
    }

    if (!valid) {
      alert('algunos campos son inválidos');
      return;
    }
    var body = {
      name, description, disableOil
    };

    if (product === 'Ac') {
      body.typeOfOil = typeOfOilSelected;
      body.brandOil = brandOilSelected;
    }
    else if (product !== 'Accs') {
      body.brand = brandSelected;
      body.model = modelSelected;
    }

    if (disableOil)
      body.price = parseFloat(price);
    else
      body.liters = liters;

    if (!query.get('id')) {
      body.stock = true;
    }

    let status = await addNewProduct(id, body, product);
    if (status) {
      alert('Datos cargados correctamente');
    }
    else {
      alert('Datos no cargados, hubo un error');
    }
    if (!query.get('id'))
      cleanFields();
  }

  function cleanFields() {
    setName('');
    setDescription('');
    setPrice(1);
    setLiters([{ liters: '1', price: '1' }]);
    setBrandSelected('');
    setModelSelected('');
    setImages([]);
    setPreviewImages([]);
    setGrayFilter([]);
  }

  /* Obtencion de datos */
  const [brandModels, setBrandModels] = React.useState({});
  const [brands, setBrands] = React.useState([]);
  const [brandsOil, setBrandsOil] = React.useState([]);
  const [oilTypes, setOiltypes] = React.useState([]);

  async function getBrands() {

    setBrands([]);
    const querySnapshot = await getBrandsAPI();
    const newBrandModels = {};
    querySnapshot.forEach((doc) => {
      const brand = { ...doc.data() };
      setBrands(prev => [...prev, brand.name]);

      const { modelsProducts } = brand;
      if (modelsProducts && modelsProducts.length === brand.models.length) {
        newBrandModels[brand.name] = brand.models.filter((item, index) => {
          return modelsProducts[index][product] ?? false;
        });
      }
      else
        newBrandModels[brand.name] = [];
    });
    setBrandModels(newBrandModels);
    if (brandSelected !== '') {
      setModelsOfSelectedBrand(newBrandModels[brandSelected]);
    }
  };

  async function getPictures() {

    const id = query.get('id');
    const product = query.get('product');

    if (!id || !product)
      return;

    await getImagesFromUpdateProduct(product, id, (images, previewImages) => {
      setGrayFilter(Array(images.length).fill('0'));
      setImages(images);
      setPreviewImages(previewImages);
    });
  };

  async function getBrandsOil() {
    const brandsOil = await getOilBrandsAPI();
    setBrandsOil(brandsOil);
  };

  async function getOilTypes() {
    const oilTypes = await getOilTypesAPI();
    setOiltypes(oilTypes);
  };

  async function getProduct(id, product) {
    const data = await getProductAPI(id, product);
    if (data === null)
      return;
    if ('description' in data)
      setDescription(data.description);
    if ('disableOil' in data)
      setDisableOil(data.disableOil);
    if ('name' in data)
      setName(data.name);
    if ('price' in data)
      setPrice(data.price);
    if ('typeOfOil' in data)
      setTypeOfOilSelected(data.typeOfOil);
    if ('brandOil' in data)
      setBrandOilSelected(data.brandOil);
    if ('brand' in data)
      setBrandSelected(data.brand);
    if ('model' in data)
      setModelSelected(data.model);
    if ('liters' in data) {
      let arrayOfLiters = [];
      for (const oil of data.liters) {
        arrayOfLiters.push({
          liters: oil.liters.toString(),
          price: oil.price.toString(),
        });
      }
      setLiters(arrayOfLiters);
    }
  }

  /* Inicializador */
  React.useEffect(() => {
    if (product !== 'Accs' && product !== 'Ac') {
      getBrands();
    }
    else if (product === 'Ac') {
      getBrandsOil();
      getOilTypes();
      setDisableOil(false);
    }
    getPictures();

    if (query.get('id')) {
      getProduct(query.get('id'), query.get('product'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Modelos de marca seleccionada */

  const [modelsOfSelectedBrand, setModelsOfSelectedBrand] = React.useState([]);

  function getModelsOfSelectedBrand() {
    if (brandSelected !== '') {
      setModelsOfSelectedBrand(brandModels[brandSelected]);
    }
    console.log(brandModels);
  }

  React.useEffect(() => {
    if (addCarItem || editModel)
      return;
    getModelsOfSelectedBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSelected, brands]);

  return (
    <div
      style={{
        marginTop: '40px',
        marginBottom: '80px',
        marginRight: '10%',
        marginLeft: '10%',
        paddingTop: '30px',
        paddingBottom: '30px',
        paddingLeft: '5%',
        paddingRight: '5%',
        border: '1px solid black',
        borderRadius: '25px',
        backgroundColor: '#f1e9e9',
      }}
    >
      <div style={{ paddingTop: '50px', width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
          Nombre del producto(título de la publicación)
        </label>
        <input
          style={{ width: '100%', height: '30px', marginBottom: '30px' }}
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
          Descripción
        </label>
        <TextField
          style={{ background: 'white', marginBottom: '50px' }}
          id="description"
          label="Descripción"
          placeholder="Placeholder"
          multiline
          maxRows={8}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          variant="outlined"
        />

        {disableOil &&
          <>
            <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Precio
            </label>
            <input
              style={{ width: '100px', height: '30px', marginBottom: '30px' }}
              type="text"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </>
        }
        {product === 'KAT' &&
          <div key="oilStatus">
            <Checkbox
              key="oilStatusCheckbox"
              value={disableOil}
              onChange={(e) => setDisableOil(e.target.checked)}
              color="default"
              inputProps={{ 'aria-label': 'checkbox with default color' }}
              defaultChecked
            />
            Deshabilitar aceite
          </div>
        }
        {!disableOil &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <label style={{ fontSize: '20px', margin: '0px 50px 15px 0', fontWeight: 'bold' }}>
                Litros
              </label>
              <label style={{ fontSize: '20px', margin: '0 15px 15px 0', fontWeight: 'bold' }}>
                Precio
              </label>
            </div>
            {liters.map((item, index) => {
              return (
                <div key={index}>
                  <input style={{ width: '60px', height: '25px', margin: '10px 38px 15px 0' }}
                    name="liters"
                    step="any"
                    value={liters[index].liters}
                    onChange={(e) => setLiters((prev) => {
                      const newArray = [...prev];
                      newArray[index].liters = e.target.value;
                      return newArray;
                    })}
                  />

                  <input style={{ width: '100px', height: '25px', margin: '0 15px 15px 0' }}
                    name="price"
                    value={liters[index].price}
                    onChange={(e) => setLiters((prev) => {
                      const newArray = [...prev];
                      newArray[index].price = e.target.value;
                      return newArray;
                    })}
                  />
                  {index === 0 &&
                    <AddCircleIcon onClick={() => { setLiters(prev => [...prev, { liters: 0, price: 0 }]); }} />}
                  {index === liters.length - 1 && index > 0 &&
                    <RemoveCircleIcon onClick={() => {
                      setLiters((prev) => {
                        const newArray = [...prev];
                        newArray.pop();
                        return newArray;
                      })
                    }} />}
                </div>
              );
            })}
          </div>
        }

        {product !== 'Accs' && product !== 'Ac' &&
          <>
            <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Marca de auto
            </label>
            <div>
              <select
                value={brandSelected}
                style={{ backgroundColor: 'white', width: '70%', height: '30px', marginBottom: '30px', fontSize: '20px', marginRight: '15px' }}
                onChange={(e) => setBrandSelected(e.target.value)}
              >
                <option value="" disabled> Selecciona una marca </option>
                {brands.map((brand, index) => {
                  return (
                    <option key={index} value={brand}> {brand} </option>
                  );
                })}
              </select>
            </div>

            <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Modelo de auto
            </label>
            <div>
              <select
                value={modelSelected}
                style={{ backgroundColor: 'white', width: '70%', height: '30px', marginBottom: '30px', fontSize: '20px', marginRight: '15px' }}
                onChange={(e) => setModelSelected(e.target.value)}
              >
                <option value="" disabled> Selecciona un modelo </option>
                {modelsOfSelectedBrand.map((model) => {
                  return (
                    <option value={model}> {model} </option>
                  );
                })}
              </select>
              <Button
                onClick={() => {
                  if (brandSelected === '')
                    alert('Selecciona una marca');
                  else {
                    setAddCarItem(true);
                    setTypeOfItem('model');
                  }
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px' }}>
                Agregar
              </Button>

              <Button
                onClick={() => {
                  if (brandSelected === '')
                    alert('Selecciona una marca');
                  else if (modelSelected === '')
                    alert('Selecciona un modelo');
                  else {
                    setEditModel(true);
                    setTypeOfItem('model');
                  }
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px', marginLeft: '10px' }}>
                Editar
              </Button>
            </div>
          </>
        }
        {product === 'Ac' &&
          <>
            <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Tipo de aceite
            </label>
            <div>
              <select
                value={typeOfOilSelected}
                style={{ backgroundColor: 'white', width: '70%', height: '30px', marginBottom: '30px', fontSize: '20px', marginRight: '15px' }}
                onChange={(e) => setTypeOfOilSelected(e.target.value)}
              >
                <option value="" disabled> Selecciona una tipo de aceite </option>
                {oilTypes.map(oil => {
                  return (
                    <option value={oil}> {oil} </option>
                  );
                })}
              </select>
              <Button
                onClick={() => {
                  setAddCarItem(true);
                  setTypeOfItem('oilType');
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px' }}>
                Agregar
              </Button>
              <Button
                onClick={() => {
                  if (typeOfOilSelected === '') {
                    alert('Selecciona una tipo de aceite');
                    return;
                  }
                  setEditModel(true);
                  setTypeOfItem('oilType');
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px', marginLeft: '10px' }}>
                Editar
              </Button>
            </div>

            <label style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
              Marca
            </label>
            <div>
              <select
                value={brandOilSelected}
                style={{ backgroundColor: 'white', width: '70%', height: '30px', marginBottom: '30px', fontSize: '20px', marginRight: '15px' }}
                onChange={(e) => setBrandOilSelected(e.target.value)}
              >
                <option value="" disabled> Selecciona una marca </option>
                {brandsOil.map(brand => {
                  return (
                    <option value={brand}> {brand} </option>
                  );
                })}
              </select>
              <Button
                onClick={() => {
                  setAddCarItem(true);
                  setTypeOfItem('brandOil');
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px' }}>
                Agregar
              </Button>
              <Button
                onClick={() => {
                  if (brandOilSelected === '') {
                    alert('Selecciona una marca');
                    return;
                  }
                  setEditModel(true);
                  setTypeOfItem('brandOil');
                }}
                variant="contained"
                color="primary"
                style={{ height: '30px', marginTop: '-10px', marginLeft: '10px' }}>
                Editar
              </Button>
            </div>
          </>
        }
      </div>
      <PicturesHandler
        images={images} setImages={setImages}
        previewImages={previewImages} setPreviewImages={setPreviewImages}
        grayFilter={grayFilter} setGrayFilter={setGrayFilter}
      />

      <Button onClick={handleUpload} variant="contained" color="primary" style={{ marginTop: '100px', marginRight: 'auto', marginLeft: 'auto', height: '100px', width: '300px', fontSize: '30px', fontWeight: 'bold' }}>
        ¡PUBLICAR!
      </Button>

      {/* Models */}
      <AddCarItem
        typeOfItem={typeOfItem}
        open={addCarItem}
        handleClose={function () {
          setAddCarItem(false);
          if (typeOfItem === 'brand') {
            getBrands();
          }
          else if (typeOfItem === 'brandOil') {
            getBrandsOil();
          }
          else if (typeOfItem === 'oilType') {
            getOilTypes();
          }
        }}
        brands={brands}
        models={modelsOfSelectedBrand}
        brandSelected={brandSelected}
        brandsOil={brandsOil}
        oilTypes={oilTypes}
        updateBrands={getBrands}
        product={product}
      />

      <EditModel
        typeOfItem={typeOfItem}
        open={editModel}
        handleClose={function () {
          setEditModel(false);
          setModelSelected('');
        }}
        brands={brands}
        models={modelsOfSelectedBrand}
        brandSelected={brandSelected}
        modelSelected={modelSelected}
        brandsOil={brandsOil}
        oilTypes={oilTypes}
        updateBrands={() => { getBrands(); getBrandsOil(); getOilTypes(); }}
        typeOfOilSelected={typeOfOilSelected}
        brandOilSelected={brandOilSelected}
      />

    </div >
  );
}