import React from 'react';

/* Sections */
import BestSellerSection from './Sections/BestSellerSection';
import TipsSection from './Sections/TipsSection';
import ShoppingInfo from './Sections/ShoppingInfo';
import ProductSections from './Sections/ProductSections.js';

import Box from '@mui/material/Box';

import logo from 'assets/logoprincipal.png'

function HomePage() {
    return (
        <div>
            <div
                style={{
                    width: '100vw',
                }}>
                <img src={logo} alt="logo" style={{ maxWidth: '80%', padding: '15px' }} />
            </div>
            <ProductSections />
            <Box
                componen={Box}
                sx={{ display: { xs: 'none', md: 'block' } }}
            >
                <BestSellerSection />
                <TipsSection />
                <ShoppingInfo />
            </Box>

            <Box
                componen={Box}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <TipsSection/>
            </Box>
        </div>
    );
}

export default HomePage;