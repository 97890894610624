import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { uploadPicture } from "utils/API/Pictures";
import { Button } from "@mui/material";
import { editPaper, getPaper, uploadPDF } from "utils/API/ArticulosAPI";
import { useQuery } from "helpers/helpers";

export default function TipsRegistro() {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionGAllery, setDescriptionGallery] = useState("");
  const [pdf, setPDF] = useState(null);
  const [uuid, setUuid] = useState(null);

  let query = useQuery();

  useEffect(() => {
    const uuid = query.get("id");
    if (uuid) {
      async function fetchData() {
        const paper = await getPaper(uuid);
        setTitle(paper.title);
        setDescription(paper.description);
        setDescriptionGallery(paper.descriptionGAllery);
      }
      fetchData();
      setUuid(uuid);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    if (e.target.files) {
      e.preventDefault();

      let reader = new FileReader();

      let file = e.target.files[0];
      reader.onloadend = () => {
        setImage(file);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePDF = (e) => {
    if (e.target.files) {
      e.preventDefault();
      let file = e.target.files[0];
      setPDF(file);
    }
  };

  async function handleUpload(e) {
    e.preventDefault();

    if (uuid) {
      // Editar el paper existente
      if (image !== null) {
        const imagesUploadCorrectly = await uploadPicture(
          `articulo/${uuid}`,
          image
        );
        if (!imagesUploadCorrectly) {
          alert("Hubo un error al subir la imagen");
          return;
        }
      }

      if (pdf !== null) {
        const path = `paper/${uuid}`;
        const paper = await uploadPDF(path, pdf);
        if (!paper) {
          alert("Hubo un error al subir el PDF");
          return;
        }
      }

      const updatedData = {
        title,
        description,
        descriptionGAllery,
      };

      const paperUpdate = await editPaper(uuid, updatedData);

      if (paperUpdate) {
        alert("El paper se actualizó correctamente");
      } else {
        alert("Hubo un error al intentar actualizar el paper");
      }
    }
  }

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "700px" },
      }}
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleUpload}
    >
      <TextField
        id="title-paper"
        label="Título"
        color="primary"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextField
        id="description-paper"
        label="Descripción breve"
        color="primary"
        multiline
        rows={5}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <TextField
        id="description-gallery"
        label="Descripción galería"
        color="primary"
        multiline
        rows={5}
        value={descriptionGAllery}
        onChange={(e) => setDescriptionGallery(e.target.value)}
      />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div> Da click para agregar un archivo PDF</div>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleChangePDF}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div> Da click para agregar una foto</div>
        <PhotoCameraIcon
          style={{ fontSize: "50px", marginLeft: "10px", marginTop: "15px" }}
        />
        <input type="file" accept="image/*" onChange={handleChange} />
      </div>

      {previewImage && (
        <img
          src={previewImage}
          alt=""
          style={{
            width: "200px",
            height: "200px",
          }}
        />
      )}

      <Button
        variant="contained"
        style={{ width: "fit-content" }}
        type="submit"
      >
        Actualizar
      </Button>
    </Box>
  );
}
