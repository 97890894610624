import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
};

export const PRODUCT = {
  KAT: 'KIT DE AFINACIÓN PARA TRANSMISIÓN',
  KAM: 'KIT DE AFINACIÓN PARA MOTOR',
  CaAc: 'CAMBIO DE ACEITE',
  Ac: 'ACEITE',
  Flt: 'FILTROS',
  Bj: 'BUJÍAS',
  Accs: 'ACCESORIOS',
  Enf: 'Enfriadores',
  Cart: 'Cárters',
};

export function validateField(target) {
  if (typeof target === 'string') {
    return target !== '';
  }
  return false;
}

export function validateNumber(target) {
  var number = parseFloat(target);
  if (isNaN(number))
    return false;
  return true;
}

export function convertToShoppingCartElement() {
  var shoppingCart = localStorage.getItem('carrito');
  if(!shoppingCart)
    return null;
  shoppingCart = shoppingCart.split("+|+");
  shoppingCart = shoppingCart.map(item => JSON.parse(item));
  return shoppingCart;
}

export function saveShoppingCar(newShoppingCar) {
  var saveShoppingCar = [...newShoppingCar.map(item => JSON.stringify(item))];
  saveShoppingCar = saveShoppingCar.join('+|+');
  localStorage.setItem('carrito', saveShoppingCar);
}