import AF from "assets/iconos/afinazone.png";
import KAT from "assets/iconos/KAT.png";
import KAM from "assets/iconos/KAM.png";
import CaAc from "assets/iconos/CaAc.png";
import Ac from "assets/iconos/Ac.png";
import Bj from "assets/iconos/Bj.png";
import Flt from "assets/iconos/Flt.png";
import Accs from "assets/iconos/Accs.png";
import Enf from "assets/iconos/Enf.png";
import Cart from "assets/iconos/Cart.png";

const ICONOS = {
  AF,
  KAT,
  KAM,
  CaAc,
  Ac,
  Bj,
  Flt,
  Accs,
  Enf,
  Cart,
}
export default ICONOS;