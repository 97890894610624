
import Grid from '@material-ui/core/Grid';

import BrokenImage from 'assets/img/imageBroken.jpeg'; 

export default function BestSellerSection(){

  return(
    <div 
      style={{
        marginRight: '10%',
        marginLeft: '10%',
      }}
    >
      <h1>  MAS VENDIDOS </h1>
      <Grid 
        container 
      >
        <Grid item xs={6} sm={3}>
          <img src={BrokenImage} alt='' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <img src={BrokenImage} alt='' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <img src={BrokenImage} alt='' />
        </Grid>
        <Grid item xs={6} sm={3}>
          <img src={BrokenImage} alt='' />
        </Grid>
      </Grid>
    </div>
  );
}