import { useState, useEffect } from 'react';

/* Options */
import Cars from './Menus/Cars';
import Catalogue from './Menus/Catalogue';
import Models from './Menus/Models';
import OilType from './Menus/OilType';
import BrandOilMenu from './Menus/BrandOilMenu';

/* API */
import { getAllProductsAPI } from 'utils/API/Product';
import { getPreviewImageUrlFromProduct } from 'utils/API/Pictures';

/* Helpers */
import { useQuery } from 'helpers/helpers';

import Box from '@mui/material/Box';

export default function OptionSelector(props) {

  let query = useQuery();

  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);

  const [typeOfOil, setTypeOfOil] = useState(null);
  const [brandOil, setBrandOil] = useState(null);

  const [products, setProducts] = useState(null);
  const [catalogue, setCatalogue] = useState(null);

  const [type, setType] = useState(null);

  useEffect(() => {

    const go = async () => {
      const productType = query.get('section');
      setType(productType);
      if (productType) {
        const arrayProducts = await getAllProductsAPI(productType);
        let array = [];
        arrayProducts.forEach(async (doc) => {
          // doc.data() is never undefined for query doc snapshots
          var url = null;
          await getPreviewImageUrlFromProduct(doc.id, productType)
            .then(r => url = r)
            .catch(e => console.log(e))
            .finally(() => {
              array.push({ ...doc.data(), url, id: doc.id });
              setProducts([...array]);
            });
        });
        setProducts(array);
      }

      var car = localStorage.getItem('car');
      if (car) {
        setBrand(car);
      }

      var model = localStorage.getItem('model');
      if (model) {
        setModel(model);
      }

      var typeOfOil = localStorage.getItem('typeOfOil');
      if (typeOfOil) {
        setTypeOfOil(typeOfOil);
      }

      var brandOil = localStorage.getItem('brandOil');
      if (brandOil) {
        setBrandOil(brandOil);
      }
    }
    go();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TYPE_REQUIRE_MODEL_AND_BRAND = {
    'KAT': true,
    'Accs': false,
    'Bj': true,
    'KAM': true,
    'CaAc': true,
    'Ac': false,
    'Flt': true,
    'Enf': true,
    'Cart': true,
  };

  function compareProduct(a, b){
    return a.name.localeCompare(b.name);
  }

  useEffect(() => {

    if (type !== 'Ac') {
      if (TYPE_REQUIRE_MODEL_AND_BRAND[type] && brand && model && products) {
        const selectedProducts = products.filter(item => (item.brand === brand && item.model === model));
        setCatalogue(selectedProducts.sort(compareProduct));
      }

      else if (!TYPE_REQUIRE_MODEL_AND_BRAND[type] && products) {
        setCatalogue(products.sort(compareProduct));
      }
    }
    else if (type === 'Ac') {
      if (typeOfOil && brandOil && products) {
        const selectedProducts = products.filter(item => (item.typeOfOil === typeOfOil && item.brandOil === brandOil));
        setCatalogue(selectedProducts.sort(compareProduct));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, model, products, typeOfOil, brandOil]);

  if (type !== 'Ac') {
    return (
      <Box
        component='div'
        sx={{
          paddingLeft: { xs: '5%', md: '15%' },
          paddingRight: { xs: '5%', md: '15%' }
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          flexDirection: 'column',
        }}
      >
        {props.optionSection === 'car' &&
          <Cars
            setOptionSection={props.setOptionSection}
            products={products}
            setBrand={setBrand}
          />
        }
        {props.optionSection === 'model' &&
          <Models
            setOptionSection={props.setOptionSection}
            products={products}
            setModel={setModel}
            brand={brand}
          />
        }
        {props.optionSection === 'product' &&
          <Catalogue
            catalogue={catalogue}
            type={type}
          />
        }
      </Box>
    );
  }

  else return (
    <Box
      component='div'
      sx={{
        paddingLeft: { xs: '5%', md: '15%' },
        paddingRight: { xs: '5%', md: '15%' }
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        flexDirection: 'column',
      }}
    >
      {props.optionSection === 'typeOfOil' &&
        <OilType
          setOptionSection={props.setOptionSection}
          products={products}
          setTypeOfOil={setTypeOfOil}
        ></OilType>
      }
      {props.optionSection === 'brandOil' &&
        <BrandOilMenu
          setOptionSection={props.setOptionSection}
          products={products}
          setBrandOil={setBrandOil}
          typeOfOil={typeOfOil}
        />
      }
      {props.optionSection === 'product' &&
        <Catalogue
          catalogue={catalogue}
          type={type}
        />
      }
    </Box>
  );
}