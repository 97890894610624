import Gallery from 'components/Gallery/Gallery';

export default function TipsSection({cols}){

  return(
    <div 
      style={{
        marginRight: '10%',
        marginLeft: '10%',
        paddingTop: '50px',
        paddingBottom: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <h1>  TIPS PARA TU VEHÍCULO </h1>
      <Gallery />
    </div>
  );
}