import { useState, useEffect } from "react";

/* Material UI */
import {
  Modal,
  Input,
  Button,
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

/* Components */
import ChipContainer from 'components/ChipContainer/ChipContainer';

/* API */
import { addNewBrandAPI, deleteBrandAPI } from 'utils/API/Brands';
import { addNewModelAPI, deleteModelAPI } from 'utils/API/Models';
import { addNewOilBrandAPI, deleteOilBrandAPI } from "utils/API/BrandsOil";
import { addNewOilTypeAPI, deleteOilTypeAPI } from "utils/API/OilTypes";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function AddCarItem(props) {

  const classes = useStyles();
  const [currentItems, setCurrentItems] = useState([]);

  const [itemsToAdd, setItemsToAdd] = useState([]);

  const [newItem, setNewItem] = useState('');

  const [errorSubmit, setErrorSubmit] = useState(false);

  function handleDeleteCurrentItems(index) {
    setCurrentItems(prev => {
      return prev.map((item, currIndex) => {
        if (index !== currIndex) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          color: item.color === 'default' ? 'secondary' : 'default',
        };
      })
    })
  }

  function handleDeleteNewItems(index) {
    setItemsToAdd(prev => {
      let newArray = prev.slice();
      newArray.splice(index, 1);
      return newArray;
    })
  }

  function handleNewItem(e) {
    e.preventDefault();
    setErrorSubmit(false);
    if (newItem === '')
      return;

    setItemsToAdd(prev => {
      let newArray = prev.slice();
      newArray.splice(0, 0, { text: newItem, color: 'default' });
      return newArray;
    });
    setNewItem('');
  }

  async function handleSubmit() {

    let success = true;
    if (newItem !== '') {
      setErrorSubmit(true);
      return;
    }

    /* Actualizamos las marcas por agregar */
    for (const item of itemsToAdd) {
      const itemToAPI = { name: item.text.toUpperCase() };
      let status = true;
      if (props.typeOfItem === 'brand') {
        status = await addNewBrandAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'brandOil') {
        status = await addNewOilBrandAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'oilType') {
        status = await addNewOilTypeAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'model') {
        status = await addNewModelAPI({ ...itemToAPI, brand: props.brandSelected, product: props.product });
      }
      success = success && status;
    }

    /* Borramos las marcas seleccionadas */
    for (const item of currentItems) {
      if (item.color === 'default')
        continue;
      const itemToAPI = { name: item.text };
      let status = true;
      if (props.typeOfItem === 'brand') {
        status = await deleteBrandAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'brandOil') {
        status = await deleteOilBrandAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'oilType') {
        status = await deleteOilTypeAPI(itemToAPI);
      }
      else if (props.typeOfItem === 'model') {
        status = await deleteModelAPI(props.brandSelected, itemToAPI.name, props.product);
      }
      success = success && status;
    }
    if (success) {
      alert('Datos actualizados correctamente');
      props.handleClose();
      setItemsToAdd([]);
      props.updateBrands();
    }
    else alert('No se pudo actualizar los datos');
  }

  useEffect(() => {
    const ITEM = {
      brand: 'brands',
      model: 'models',
      brandOil: 'brandsOil',
      oilType: 'oilTypes',
    };
    if (!props.typeOfItem)
      return;
    setCurrentItems(props[ITEM[props.typeOfItem]].map(item => { return { text: item, color: 'default' } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.brands, props.models, props.oilTypes, props.brandsOil, props.typeOfItem])

  const PRODUCTS = {
    brand: 'Marcas',
    model: 'Modelos',
    brandOil: 'Marcas de aceite',
    oilType: 'Tipos de aceite',
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
      >
        <div className={classes.paper}>
          {props.typeOfItem === 'model' && <div> Marca: {props.brandSelected}  </div>}

          {PRODUCTS[props.typeOfItem]} actuales
          <ChipContainer
            chipData={currentItems}
            handleDelete={handleDeleteCurrentItems}
          />
          {PRODUCTS[props.typeOfItem]} por agregar
          <ChipContainer
            chipData={itemsToAdd}
            handleDelete={handleDeleteNewItems}
          />
          Añadir {PRODUCTS[props.typeOfItem]}
          <form onSubmit={handleNewItem}>
            <Input
              value={newItem}
              onChange={e => { e.preventDefault(); setNewItem(e.target.value); }}
              error={errorSubmit}
            />
          </form>
          <Button
            style={{ margin: '10px' }}
            variant="contained"
            onClick={handleSubmit}
            color='primary'
          >
            Subir información
          </Button>
        </div>
      </Modal>
    </div>
  );
}