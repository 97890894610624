
export default function Header(props) {

  return (
    <div>
      <h1
        style={{
          marginTop: '40px',
          marginBottom: '30px',
          marginRight: '15%',
          marginLeft: '15%',
          paddingTop: '30px',
          paddingBottom: '30px',
          border: '1px solid black',
          borderRadius: '25px',
          backgroundColor: '#bec1c2',
        }}> {props.children} </h1>
    </div>
  );
}