import React from 'react';

import ICONOS from 'helpers/iconos';
import { Grid } from '@mui/material';

function EnviosyPagos() {
  var pageStyle = {
    padding: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "100%",
  }
  
  var titleStyle = {
    borderBottom: '1px solid #000000',
  }
  
  var contentStyle = {
    padding: "10px"
  }

  const boldSection = {
    fontWeight: "900",
    fontSize: "20px",
  };
  
  return (
    <div style={pageStyle}>

      <Grid container 
        direction="column"
        justifyContent="center" 
        alignItems="center"
      >
        
        <Grid container xs={10} md={8} 
          direction="row"
          justifyContent="center" 
          alignItems="center"
          style={titleStyle}
        >
          <img
            src={ICONOS.AF} alt="AF"
            style={{ height: '50px', width: '50px', }}
          />
          <h2>Envíos</h2>
        </Grid>

        <Grid container xs={10} md={8} 
          align="left"
          style={contentStyle}
        >
          <div>
          <p style={boldSection}>¿Cuál es el tiempo de entrega?</p>
          <p>El tiempo de entrega de nuestros productos suelen ser de 2 a 5 días hábiles en nuestro envío
            estándar si el envío no es a una zona extendida, pero si su domicilio es zona extendida el tiempo
            de envío será de 5 a 10 días hábiles. </p>
          <p>Para una estimación más certera en el tiempo de entrega de su producto podrá comunicarse con
            nosotros a través de nuestros medios de contacto enviando su código postal, le responderemos a
            la brevedad.</p>

          <p style={boldSection}>¿Qué puedo hacer si me urge el producto?</p>
          <p>Si requiere un envío EXPRESS del producto con un tiempo de entrega de 1 a 2 días hábiles después
            del despacho tendrá costo extra y tendrá que comunicarse con nosotros vía WhatsApp antes de
            realizar la compra para compartirnos el domicilio y valorar si podemos ofrecerle el servicio
            EXPRESS, así como el costo extra que posteriormente le orientaremos para proceder al pago.</p>

          <p style={boldSection}>¿Dónde puedo darle seguimiento al paquete?</p>
          <p>Una vez realizada la compra, le compartiremos el número de guía y la paquetería vía WhatsApp
            para que pueda darle seguimiento en el sitio web de la paquetería o bien puede preguntarnos en
            nuestro chat de WhatsApp y con gusto lo ayudamos.</p>

          <p style={boldSection}>¿Cuál es el costo del envío?</p>
          <p>Si el costo de tu producto es mayor o igual a $549.00 entonces el envío será gratis en envío
            ESTÁNDAR.</p>
          <p>Si tu producto es menor a $549.00 le sugerimos agregar más productos a su carrito hasta superar
            esta cantidad, de lo contrario el costo del envío será de $149.00 en envío ESTÁNDAR.</p>

          <p style={boldSection}>¿Desde qué ciudad se envían los productos?</p>
          <p>Nuestros envíos se realizan desde la ciudad de Celaya, Guanajuato a toda la república mexicana
            por distintas paqueterías con las que tenemos convenios como PAQUETEXPRESS, FEDEX,
            ESTAFETA entre otras, una vez confirmado el pago nosotros enviaremos a la brevedad su producto
            para que lo tenga en sus manos tan rápido como sea posible, el producto será despachad en el
            mismo día en caso de aún estar en horario de trabajo de la paquetería, de lo contrario se
            despachará al día hábil siguiente.</p>

          <p style={boldSection}>¿A dónde lo enviaremos?</p>
          <p>El domicilio a donde lo enviaremos será el que usted ingrese en la sección de pago del producto, si
            usted desea enviarlo a otro domicilio o se equivocó puede comunicarse con nosotros en nuestros
            medios de contacto como WhatsApp, Facebook e Instagram los cuales aparecen en la parte de
            arriba de esta página.</p>

          <p style={boldSection}>Cualquier duda que tenga respecto al envío podemos ayudarlo en cualquiera de nuestros medios
            de contacto.</p>
          </div>
        </Grid>


        <Grid container xs={10} md={8} 
          direction="row"
          justifyContent="center" 
          alignItems="center"
          style={titleStyle}
        >
          <img
            src={ICONOS.AF} alt="AF"
            style={{ height: '50px', width: '50px', }}
          />
          <h2>Pagos</h2>
        </Grid>

        <Grid container xs={10} md={8} 
          align="left"
          style={contentStyle}
        >
          <p>Te ofrecemos realizar los pagos de manera segura con tarjeta de crédito, débito y PayPal a través
            de nuestra página web, de esta manera los pagos son totalmente seguros y se acreditan al
            instante, al momento de la acreditación te enviaremos un mensaje o correo con la confirmación
            de tu compra.</p>
          <p>También se pueden realizar los pagos vía transferencia electrónica, para hacerlo de esta manera
            tendrá que comunicarse vía redes sociales para compartirle los datos para transferencia o
            depósito.</p>
        </Grid>


        <Grid container xs={10} md={8} 
          direction="row"
          justifyContent="center" 
          alignItems="center"
          style={titleStyle}
        >
          <img
            src={ICONOS.AF} alt="AF"
            style={{ height: '50px', width: '50px', }}
          />
          <h2>Devoluciones</h2>
        </Grid>

        <Grid container xs={10} md={8} 
          align="left"
          style={contentStyle}
        >
          <p>El cliente podrá realizar la devolución de la mercancía durante los primeros 30 días después de
            recibido el paquete.</p>
          <p>En caso de requerir la devolución del producto favor de comunicarse con nosotros vía WhatsApp,
            Instagram o Facebook, ahí le compartiremos la dirección a la cual se hará la devolución.</p>
          <p>La devolución corre por cuenta del cliente, por lo que podrá elegir la paquetería de su elección o si
            usted prefiere nosotros le podemos apoyar generando la guía, únicamente tendría que cubrir el
            costo de esta anteriormente cotizada con nosotros.</p>
          <p>Para que nosotros aceptemos la devolución debemos recibir el mismo producto enviado, en las
            mismas condiciones que se mandó, una vez recibido y examinado el reembolso se efectuará de 48
            a 72 horas a partir de la recepción del producto. Nos pondremos en contacto con usted para
            confirmarle la devolución y en caso de que AFINAZONE encuentre alguna irregularidad como
            daños o falta de accesorios no se aceptará la devolución y se reenviará el producto sin previo
            aviso.</p>
          <p>Si su compra fue realizada por PayPal, se deberá considerar un periodo de 8 a 10 días hábiles para
            que pueda verse reflejado el reembolso en su cuenta. Si se realizó vía tarjeta de crédito o débito el
            tiempo que deberá considerar será de aproximadamente 25 días hábiles.</p>
        </Grid>


        <Grid container xs={10} md={8} 
          direction="row"
          justifyContent="center" 
          alignItems="center"
          style={titleStyle}
        >
          <img
            src={ICONOS.AF} alt="AF"
            style={{ height: '50px', width: '50px', }}
          />
          <h2>Facturación</h2>
        </Grid>

        <Grid container xs={10} md={8} 
          align="left"
          style={contentStyle}
        >
          <p>Los costos de los artículos mostrados son precios netos.</p>
          <p>Puedes solicitar factura mandando los datos fiscales al correo afinazone@outlook.com o también
            a nuestro WhatsApp adjuntando el número de tu pedido.</p>
          <p>Te sugerimos comunicarnos si requieres factura del producto para generarla a la brevedad.</p>
        </Grid>


      </Grid>

    </div>
  );
}

export default EnviosyPagos;
