import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ICONOS from 'helpers/iconos';

/* Search */
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

/* API */
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";
import { getAllProductsAPI } from 'utils/API/Product';

/* Helpers */
import { PRODUCT } from '../../helpers/helpers';

/* Routing */
import {
    useHistory
} from "react-router-dom";
import { COLORS } from 'utils/Constants/Colors';

import logo from 'assets/afinazoneTransparente.png';

import Box from '@mui/material/Box';
import BurguerMenu from 'components/BurguerMenu/BurguerMenu';

const filterOptions = createFilterOptions({
    stringify: (option) => option.name,
});

function SearchField(params) {

    return (
        <Paper
            ref={params.InputProps.ref}
            component="form"
            onSubmit={e => { 
                e.preventDefault(); 
                params.handleClickRoute(`Busqueda?filterValue=${params.value}`);
                window.location.reload();
            }}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 'full' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Busca tu producto"
                {...params.inputProps}
                inputProps={{
                    'aria-label': 'search google maps',
                }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon onClick={() => {
                    params.handleClickRoute(`Busqueda?filterValue=${params.value}`);
                    window.location.reload();
                }}
                />
            </IconButton>
        </Paper>
    );
}

function SearchBar() {

    const [listOfAllProducts, setlistOfAllProducts] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const ref = useRef(inputValue);

    async function getAllProducts() {
        for (const product in PRODUCT) {
            const listOfProducts = await getAllProductsAPI(product);
            listOfProducts.forEach(async (doc) => {

                setlistOfAllProducts(prev =>
                    [...prev, { id: doc.id, name: doc.data().name, type: PRODUCT[product], typeKey: product }]);
            })
        }
    };

    useEffect(() => {
    }, [listOfAllProducts]);


    useEffect(() => {
        getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Funcitons from routing */

    let history = useHistory();

    function handleClickRoute(route) {
        history.push(route);
    }

    const [user, setUser] = useState(null);

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            setUser(user);
        }
    });

    const compareProduct = (a, b) => {
        if (a.type.localeCompare(b.type) === 0) {
            return a.name.localeCompare(b.name);
        }
        return a.type.localeCompare(b.type);
    };

    const redirectPage = (productSelected) => {
        if (!productSelected)
            return;
        const product = listOfAllProducts.find(e => e.name === productSelected);

        if (product) {
            history.push(`/Producto?product=${product.typeKey}&id=${product.id}`);
            window.location.reload();
        }
    };

    return (
        <>
            <AppBar position="sticky" style={{
                color: 'white',
                background: `linear-gradient(to bottom , ${COLORS.back} 50%, ${COLORS.primary} 100%)`,
                borderStyle: "solid",
                borderBottom: '2px solid white',
                borderWidth: "0px",
                display: 'flex',
                flexDirection: 'row',
            }}>
                <Grid container style={{
                    fontWeight: 'bold',
                }}>
                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                        item xs={2}
                    >
                        <BurguerMenu />
                    </Grid>

                    <Grid item xs={6} sm={2} style={{ cursor: 'pointer' }} onClick={() => handleClickRoute('')}>
                        <img src={logo} alt={'logo'} style={{ height: '50px', padding: '10px' }} />
                    </Grid>

                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        item sm={2} style={{ cursor: 'pointer' }} onClick={() => handleClickRoute('Tips')}>
                        <img
                            src={ICONOS.AF} alt="AF"
                            style={{ height: '50px', width: '50px', }}
                        />
                        <div>Tips Para Tu Vehículo</div>
                    </Grid>
                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        item sm={1} style={{ cursor: 'pointer' }} onClick={() => handleClickRoute('EnviosyPagos')}>
                        <img
                            src={ICONOS.AF} alt="AF"
                            style={{ height: '50px', width: '50px', }}
                        />
                        <div>Envíos y Pagos</div>
                    </Grid>
                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        item sm={1} style={{ cursor: 'pointer' }} onClick={() => handleClickRoute('Nosotros')}>
                        <img
                            src={ICONOS.AF} alt="AF"
                            style={{ height: '50px', width: '50px', }}
                        />
                        <div>Nosotros</div>
                    </Grid>
                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        item sm={1} style={{ cursor: 'pointer' }} onClick={() => handleClickRoute('Contacto')}>
                        <img
                            src={ICONOS.AF} alt="AF"
                            style={{ height: '50px', width: '50px', }}
                        />
                        <div>Contacto</div>
                    </Grid>

                    <Grid
                        component={Box}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        item xs={4} sm={3}
                        style={{ padding: '15px' }}
                    >
                        <Autocomplete
                            id="search-field-user"
                            options={listOfAllProducts.sort((a, b) => compareProduct(a, b))}
                            groupBy={(option) => option.type}
                            getOptionLabel={(option) => option.name}
                            filterOptions={filterOptions}
                            renderInput={(params) => {
                                return <SearchField {...params} handleClickRoute={handleClickRoute} value={inputValue} />
                            }}
                            onChange={(e) => redirectPage(e.target.textContent)}
                            style={{ marginRight: '10px' }}
                            onInputChange={(e, v) => { setInputValue(v); ref.current = v; }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} style={{ padding: '15px' }}>
                        <ShoppingCartIcon
                            fontSize="large"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClickRoute('/Carrito')} />
                        {user !== null &&
                            <AdminPanelSettingsIcon
                                fontSize="large"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleClickRoute('/PanelAdministracion')} />
                        }
                    </Grid>
                </Grid>

            </AppBar>
            <Box
                sx={{ display: { xs: 'block', md: 'none' } }}
                style={{ padding: '15px' }}
            >
                <Autocomplete
                    id="search-field-user"
                    options={listOfAllProducts.sort((a, b) => compareProduct(a, b))}
                    groupBy={(option) => option.type}
                    getOptionLabel={(option) => option.name}
                    filterOptions={filterOptions}
                    renderInput={(params) => {
                        return <SearchField {...params} handleClickRoute={handleClickRoute} value={inputValue} />
                    }}
                    onChange={(e) => redirectPage(e.target.textContent)}
                    style={{ marginRight: '10px' }}
                    onInputChange={(e, v) => { setInputValue(v); ref.current = v; }}
                />
            </Box>
        </>
    );
}

export default SearchBar;