import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { getAllPapers } from "utils/API/ArticulosAPI";
import { getPreviewImageUrlFromPaper } from "utils/API/Pictures";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

export default function TitlebarImageList() {
  const [papers, setPapers] = React.useState([]);
  let history = useHistory();

  React.useEffect(function () {
    async function go() {
      const papers = await getAllPapers();
      for (const paper of papers) {
        paper.url = await getPreviewImageUrlFromPaper(paper.id);
      }
      papers.sort((a, b) => a.order - b.order);
      setPapers(papers);
    }
    go();
  }, []);

  return (
    <>
      <Box component={Box} sx={{ display: { xs: "none", md: "block" } }}>
        <ImageList sx={{ width: "1050px", height: "500px" }} cols={4}>
          {papers.map((item) => (
            <ImageListItem
              key={item.url}
              cols={1}
              style={{
                width: "100%",
                height: "250px",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/Paper?id=${item.id}`)}
            >
              <img
                style={{
                  width: "100%",
                  height: "250px",
                }}
                src={item.url}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.descriptionGAllery}
                sx={{
                  "& .MuiImageListItemBar-title": {
                    whiteSpace: "normal",
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      <Box component={Box} sx={{ display: { xs: "block", md: "none" } }}>
        <ImageList sx={{ width: "320px", height: "300px" }} cols={2}>
          {papers.map((item) => (
            <ImageListItem
              key={item.url}
              cols={1}
              style={{
                width: "100%",
                height: "150px",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/Paper?id=${item.id}`)}
            >
              <img
                style={{
                  width: "100%",
                  height: "150px",
                }}
                src={item.url}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.descriptionGAllery}
                sx={{
                  "& .MuiImageListItemBar-title": {
                    whiteSpace: "normal",
                    fontSize: "12px",
                    lineHeight: "1", 
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}
